import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 0,
    marginTop: HEADER_HEIGHT,
    backgroundColor: theme.colors.backgroundGray[0]
  },

  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  avatar: {
    cursor: 'pointer'
  },
  user: {
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[8]
      },
      [utils.light]: {
        backgroundColor: theme.white
      }
    }
  },
  userActive: {
    [utils.dark]: {
      backgroundColor: theme.colors.dark[8]
    },
    [utils.light]: {
      backgroundColor: theme.white
    }
  },
  account: {
    cursor: 'pointer'
  },
  nickname: {
    [utils.smallerThan('xs')]: {
      display: 'none'
    }
  }
}));
