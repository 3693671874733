import React, { FC, MouseEvent } from 'react';
import { Box, Checkbox, Text } from '@mantine/core';
import { useStyles } from './styles';

interface IGroupItemProps {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  label: string;
  handleChange?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  value: number;
  counter?: number;
  withCheckbox?: boolean;
  selectedIdGroup?: number | null;
}

export const GroupItem: FC<IGroupItemProps> = ({
  defaultChecked,
  checked,
  disabled,
  label,
  value,
  counter,
  handleChange,
  handleClick,
  withCheckbox = false,
  selectedIdGroup
}) => {
  const { classes, cx } = useStyles();

  const handleItemClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (withCheckbox && handleChange) {
      handleChange(e);
    }
    if (handleClick) {
      handleClick(e);
    }
  };

  return (
    <Checkbox.Card
      onClick={handleItemClick}
      data-value={value}
      checked={checked}
      disabled={disabled}
      defaultChecked={defaultChecked ?? undefined}
      className={cx(classes.root, {
        [classes.selectedGroup]: Boolean(selectedIdGroup) && value === selectedIdGroup,
        [classes.disabled]: disabled
      })}
    >
      {withCheckbox ? (
        <>
          <Checkbox.Indicator disabled={disabled} />
          <Box className={classes.checkboxContainer}>
            <Text
              sx={{
                cursor: disabled ? 'not-allowed' : 'pointer'
              }}
              className={classes.checkbox}
            >
              {label}
            </Text>
            <Text className={classes.counter}>{counter && counter}</Text>
          </Box>
        </>
      ) : (
        <Box className={classes.checkboxContainer} data-value={value}>
          <Text className={classes.checkbox}>{label}</Text>
          <Text className={classes.counter}>{counter && counter}</Text>
        </Box>
      )}
    </Checkbox.Card>
  );
};
