import React from 'react';
import { Box, PasswordInput, Group, Checkbox, Text, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';

interface PasswordStepProps {
  form: any;
  handleChangePassword: (value: string) => void;
  classes: any;
  t: TFunction;
}

export const PasswordStep = ({ form, handleChangePassword, classes, t }: PasswordStepProps) => {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <Text className={classes.subtitle}>Придумайте надежный пароль:</Text>
      </Box>
      <PasswordInput
        size="lg"
        radius="md"
        variant="filled"
        name="Password"
        placeholder={t('register.password')}
        {...form.getInputProps('Password')}
        onChange={(event) => handleChangePassword(event.currentTarget.value)}
      />
      <Group justify="space-between" mb={12} mt={25} pl={20} gap="xs">
        <Checkbox
          className={classes.checkbox}
          label={t('register.subscribeAccept')}
          size="sm"
          {...form.getInputProps('AgreementsConfirmedInfo', { type: 'checkbox' })}
        />
        <Checkbox
          className={classes.checkbox}
          {...form.getInputProps('AgreementsConfirmedRule', { type: 'checkbox' })}
          label={
            <span>
              {t('register.accept')}{' '}
              <Button
                variant="subtle"
                className={classes.checkboxLink}
                component={Link}
                target="_blank"
                to="https://r7-office.ru/doc/terms_of_service"
              >
                {t('register.serviceRules')}
              </Button>{' '}
              {t('register.and')}{' '}
              <Button
                variant="subtle"
                className={classes.checkboxLink}
                component={Link}
                target="_blank"
                to="https://r7-office.ru/doc/personal_data_processing_policy"
              >
                {t('register.privacyPolicy')}
              </Button>
            </span>
          }
          size="sm"
        />
      </Group>
    </Box>
  );
};
