import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    overflow: 'auto',
    [utils.smallerThan('md')]: {
      marginRight: '0'
    }
  },
  charts: {},
  w: {
    overflow: 'auto'
  },

  container: {
    backgroundColor: theme.white,
    borderRadius: '8px',
    overflowX: 'hidden',
    [utils.smallerThan('md')]: {
      padding: '10px',
      marginTop: '10px'
    },
    '@media (max-width: 992px)': {
      marginRight: '16px'
    }
  },

  table: {
    '& table': {
      '& tbody': {
        '& tr:first-of-type': {
          fontWeight: 'bold',
          backgroundColor: '#F2F4F7'
        }
      },
      '& thead': {
        '& th .mantine-Group-root': {
          gap: '11px!important' as any
        }
      }
    }
  },

  divider: {
    borderColor: theme.colors.backgroundGray[2]
  },

  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: 'white'
  }
}));
