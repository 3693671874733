import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  table: {
    width: '100%'
  },
  header: {
    position: 'sticky',
    top: 0,
    [utils.dark]: {
      backgroundColor: theme.colors.dark[7]
    },
    [utils.light]: {
      backgroundColor: theme.white
    },
    transition: 'box-shadow 150ms ease',
    zIndex: 300
  },

  scrolled: {
    boxShadow: theme.shadows.sm
  },

  tr: {
    padding: '0 !important',
    position: 'relative',
    backgroundColor: 'white'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  error: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    '& p': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px 10px'
    }
  },

  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // width: '100%',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: 'white'
  }
}));
