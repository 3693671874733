import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.white,
    borderRadius: theme.spacing.xs,
    padding: theme.spacing.md
  },

  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  textField: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  },

  control: {
    button: {
      width: '100%'
    },
    [utils.largerThan('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      button: {
        width: 'auto'
      }
    },
    [utils.smallerThan('xs')]: {
      'button:first-of-type': {
        marginBottom: 20
      }
    }
  }
}));
