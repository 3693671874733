import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    width: '100%'
  },

  input: {
    width: '100%',
    marginRight: theme.spacing.xs,

    '& .mantine-PasswordInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  },

  generateButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: theme.colors.gray[8],
    border: `1px solid #DEE2E6`,
    padding: '3px 10px',
    borderRadius: '4px',
    width: '100%'
  },

  icon: {
    flex: '0 0 160px',
    height: '36px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    svg: {
      marginRight: '5px'
    }
  },

  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginTop: '8px'
  },

  passwordProgress: {},
  passwordStatus: {
    color: theme.colors.gray[8]
  },
  hint: {
    color: theme.colors.gray[6],
    margin: '5px 0 24px 0'
  },

  progress: {
    marginBottom: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    width: '100%'
  }
}));
