import { Stack, Text } from '@mantine/core';
import { FC } from 'react';
import { useStyles } from './styles';
import { IModuleItem } from '../sections/modules';
import { useNavigate } from 'react-router-dom';

interface IModuleItemProps {
  item: IModuleItem;
}

export const ModuleItem: FC<IModuleItemProps> = ({ item }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleRedirect = (item: IModuleItem) => {
    navigate(item.link);
  };

  return (
    <Stack
      gap="4px"
      align="center"
      justify="center"
      w="72px"
      h="72px"
      className={classes.moduleItem}
      onClick={() => handleRedirect(item)}
    >
      <img src={item.icon} alt="icon" width="36px" />
      <Text fz="12px" fw="400">
        {item.label}
      </Text>
    </Stack>
  );
};
