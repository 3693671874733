import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonSettings } from './types';

const initialState: ICommonSettings = {
  TeamSettings: {
    IsEnabled: false,
    Url: null,
    ClientId: null,
    ClientSecret: null,
    RabbitData: null
  },
  CommonViewSettings: {
    Name: 'Р7-Офис',
    MainLogoId: 4553,
    BrandColor: '#118eef',
    MainLogoUrl: 'https://cddisk.r7-office.ru/api/v1/files/stream?id=4553'
  }
};

export const commonSettingsSlice = createSlice({
  name: 'commonSettings',
  initialState,
  reducers: {
    setCommonSettings(state, action: PayloadAction<ICommonSettings>) {
      state.CommonViewSettings = action.payload.CommonViewSettings;
      state.TeamSettings = action.payload.TeamSettings;
    }
  }
});

export const commonSettingsActions = commonSettingsSlice.actions;
export const commonSettingsReducer = commonSettingsSlice.reducer;
