import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { IDepartment, IRole, IAddress, IGetRolesResponse } from './types';
import { IFullResponse, IFullResponseV2, ISimpleFiltersQuery } from '@/types/api';
import { ComboboxItem } from '@mantine/core';
import { customBaseQuery } from '@/utils/customBaseQuery';

const tagTypes = ['Role', 'Departments', 'Address', 'RolesWithCounter'];

export const directoriesApi = createApi({
  reducerPath: 'api/directories',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getRolesList: build.query<IRole[], void>({
      query: () => ({
        url: `v1/Role/List`
      }),
      transformResponse: (response: IRole[]) => response,
      providesTags: ['Role']
    }),
    getDepartmentsList: build.query<IDepartment[], void>({
      query: () => ({
        url: `v1/Departments/All`
      }),
      transformResponse: (response: IDepartment[]) => response,
      providesTags: ['Departments']
    }),
    getAddressList: build.query<ComboboxItem[], string>({
      query: (payload: string) => ({
        url: `v2/Address/Search`,
        body: {
          Search: payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponseV2<IAddress[]>) =>
        response.Response.Data.Result.map((item) => ({
          label: item.Name,
          value: item.Name,
          id: item.Id
        })),
      providesTags: ['Address']
    }),
    getRolesWithCounter: build.query<IRole[], void>({
      query: () => ({
        url: `v1/role/ListWithCounter`
      }),
      transformResponse: (response: IRole[]) => response,
      providesTags: ['RolesWithCounter']
    }),
    getRolesForUser: build.query<IGetRolesResponse, ISimpleFiltersQuery>({
      query: (payload: ISimpleFiltersQuery) => ({
        url: `v2/roles/list`,
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IGetRolesResponse>) => {
        return response.Response.Data;
      }
    }),
    createRole: build.mutation<IRole, IRole>({
      query: (payload: IRole) => ({
        url: `v1/role`,
        body: { ...payload },
        method: 'post'
      }),
      transformResponse: (response: IRole) => response
    })
  })
});

export const {
  useGetRolesListQuery,
  useGetDepartmentsListQuery,
  useGetRolesWithCounterQuery,
  useCreateRoleMutation,
  useLazyGetAddressListQuery,
  useGetRolesForUserQuery
} = directoriesApi;
