import { ModuleAccess, ModuleSettings } from '@/entities/admin-app/auth';
import { ROUTES } from '@/containers/routes/constants';

export const isDev = import.meta.env.DEV;

const getDevUrl = (name: ModuleAccess) => {
  if (name === 'Disk') return 'https://drive-app.r7-office.ru';
  if (name === 'Mail') return 'https://mail-app.r7-office.ru';
  if (name === 'Calendar') return 'https://calendar-app.r7-office.ru';
  if (name === 'Draw') return 'https://draw-app.r7-office.ru';
  if (name === 'Contacts') return 'https://mail-app.r7-office.ru/addressbook';
  if (name === 'Projects') return 'https://projects-app.r7-office.ru';
  if (name === 'Admin') return ROUTES.home.fullPath;
};

export const getMenu = (modules: ModuleSettings) => {
  return modules.ModuleUrls.filter((module) => module && module.HasAccess && module.ShowItem).map(
    (module) => ({
      label: module.Title,
      iconSrc: module.Icon,
      url: isDev ? getDevUrl(module.Name as ModuleAccess) : module.Url
    })
  );
};
