import React, { FC } from 'react';
import { Box, Flex } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { EmailActivationControl } from '@components/email-activation/emailActivationControl';
import OverflowTip from '@components/OverflowTip';

interface IItemEmailProps {
  item?: IRowData;
}

export const ItemEmail: FC<IItemEmailProps> = ({ item }) => {
  const { classes } = useStyles();

  if (!item?.Email || !item?.Id) {
    return null;
  }

  return (
    <Flex className={classes.container}>
      <Box className={classes.emailBox}>
        {!item?.IsConfirmed && item?.Email ? (
          <EmailActivationControl
            Email={item?.Email}
            Id={item?.Id}
            customerId={item?.Customer?.Id}
          />
        ) : null}
      </Box>
      <Box maw={200}>
        <OverflowTip fz="sm">{item?.Email}</OverflowTip>
      </Box>
    </Flex>
  );
};
