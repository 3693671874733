import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: `${theme.spacing.sm} ${theme.spacing.sm} 0px ${theme.spacing.md}`,
    [utils.largerThan('xs')]: {
      width: '60%'
    }
  },

  container: {
    width: '100%',
    height: '100%'
  },

  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  search: {
    marginBottom: theme.spacing.md,
    '& input': {
      border: '1px solid #EAECF0'
    }
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  emptyListText: {
    fontSize: '14px',
    textAlign: 'center'
  }
}));
