import { useState } from 'react';

import { Search } from '@components/search/search-input';
import { SearchIcon } from '@assets/icons';
import { useStyles } from './styles';
import { Box } from '@mantine/core';

export const LicensesSearch = () => {
  const { classes } = useStyles();

  const [text, setText] = useState('');

  return (
    <Box className={classes.searchWrapper}>
      <Search
        fullSize
        placeholder="Поиск активаций"
        value={text}
        setSearch={setText}
        icon={<SearchIcon />}
        className={classes.search}
      />
    </Box>
  );
};
