import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [utils.largerThan('xs')]: {
      flexDirection: 'row'
    },
    [utils.smallerThan('xs')]: {
      button: {
        // width: '210px',
        marginBottom: 20
      }
    }
  },
  rightSection: {
    input: {
      width: '85px'
    }
  },

  filterSection: {
    [utils.smallerThan('xs')]: {
      marginTop: '20px'
    }
  },

  countText: {
    [utils.smallerThan('xs')]: {
      fontSize: theme.fontSizes.xs,
      marginRight: 10
    }
  },

  selectItem: {
    backgroundColor: theme.white,
    cursor: 'pointer',
    padding: '6px',
    '.mantine-Group-root': {
      gap: '12px'
    },
    '.mantine-Radio-radio': {
      width: theme.spacing.sm,
      height: theme.spacing.sm
    }
  }
}));
