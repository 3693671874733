export enum GenderEnum {
  male = 1,
  female = 2
}

export enum NavbarAddButtonMenuEnum {
  user = 'user',
  group = 'group',
  inviteUsers = 'invite-users',
  importUsersOrGroups = 'import-users-or-groups'
}

export enum usersActionsEnumType {
  logOut = 264,
  successLogin = 260,
  loadDoc = 121,
  errorAttempt = 263,
  openDocExternalLink = 126,
  openDoc = 125,
  editDoc = 127,
  downloadDoc = 120,
  downloadDocLink = 124,
  createExternalLink = 26,
  createDoc = 23,
  createLink = 25,
  deleteDocFromFolder = 180,
  deleteDocFromGlobalTrash = 193,
  deleteDocFromTrash = 192,
  deleteFromFavorites = 182,
  deleteCalendar = 19,
  deleteDirectory = 181,
  deleteEvent = 184,
  moveDirectory = 190,
  restoreDirectory = 191,
  documentMove = 199,
  accessToProject = 132,
  accessToCalendar = 131,
  accessToEvent = 130,
  getDocList = 41,
  addDocToFav = 28,
  addContactToFav = 27,
  deleteUser = 268,
  deleteUserWithTransfer = 269
}

export enum IFiltersEnumType {
  RoleFilter = 1,
  UserStatusFilter = 2,
  FilialFilter = 3,
  PositionFilter = 4,
  SupervisorFilter = 5,
  RoleTypeFilter = 6,
  UserTypeFilter = 7,
  GenderFilter = 8,
  EmailStatusFilter = 9,
  AddressFilter = 10,
  BirthDateFilter = 11,
  DateOfCreateFilter = 12,
  LastVisitDateFilter = 13,
  QuotaFilter = 14,
  UsingQuotaSizeFilter = 15,
  UserFilter = 16,
  AuthActionTypeFilter = 17
}

export enum ErrorsEnum {
  ObjectNotFound = 51001,
  ObjectIsNotActive = 51003,
  NoRights = 51006,
  UserIsExists = 51010,
  CustomerIsExists = 51011,
  EmptyParameter = 51012,
  InternalError = 51013,
  EmptyCustomerName = 51014,
  CustomerIsNotExists = 51015,
  PasswordNotChanges = 51016,
  UserIsNotExists = 51017,
  ParentNotSet = 51018,
  NotFoundAll = 51019,
  EmptyOrWhiteSpaceLogin = 51020,
  EmptyCustomer = 51021,
  NotRightsForSuperAdmin = 51025,
  NotRightsForSelf = 51026,

  InvalidLoginOrPassword = 52001,
  InvalidPassword = 52006,
  InvalidEmailFormat = 52007,
  ToManyRequests = 52008,

  NotConfiguredCustomersEmail = 54001,
  NotSendEmail = 54002,
  DontHaveEmail = 54003,
  EmailAlreadyActivated = 54004,
  EmailNotActivated = 54005,
  InputEmptyEmail = 54006,
  NotExistsMxRecords = 54007,
  CantCreateDomain = 54008,
  NotFoundEmailServer = 54009,
  EmailAlreadyLinked = 54010,
  EmailIsNotExists = 54011,
  NotFoundImapFolder = 54012,
  CantCreateEmail = 54013,
  CantLinkEmail = 54014,
  EmailExists = 54015,
  InvalidEmail = 54016,
  EmailServerError = 54017,
  AdditionalEmailEqualsEmail = 54018,

  NotConfiguredPasswordSecurity = 55001,
  IncorrectPasswordSecurityMinLength = 55002,
  NotConfiguredCanRegisterCustomer = 55003,
  SetupEmailSshIntegration = 55004,
  NoPackInstance = 55005,
  NotExistsEmailConfirmTemplate = 55006,
  NotSetCommandUrl = 55007,
  WrongCommonViewSettingsName = 55008,
  NotFoundLdapField = 55009,
  FailLdapAuth = 55010,

  RoleNotFound = 56001,
  CanNotDeleteAdminSuperAdmin = 56002,
  CanNotHaveParent = 56003,
  SelectCustomer = 56004,
  CanNotSelfParent = 56005,

  EmptyQuotaName = 57001,
  ZeroQuotaSize = 57002,
  LongQuotaName = 5700,
  NotFoundQuota = 57004,

  CustomerHasUsers = 58001,

  UserExistsByPhone = 51024,
  UserExistsByLogin = 51023,
  UserExistsByEmail = 51022,

  MaxFilialQuota = 58002
}
