import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@/components/header/constants';

export const useStyles = createStyles((theme) => ({
  subscriptionContainer: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#f6f7f9',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    overflow: 'scroll',
    padding: `${theme.spacing.md}`
  },

  flexContainer: {
    flexWrap: 'wrap',
    [`@media (max-width: 735px)`]: {
      justifyContent: 'center'
    }
  }
}));
