import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const isDev = import.meta.env.DEV;
const isMob = import.meta.env.VITE_MOB;

export const customBaseQuery = (url: string) =>
  fetchBaseQuery({
    baseUrl: url,

    prepareHeaders: async (headers) => {
      headers.set('Content-Type', 'application/json');

      if (isDev && isMob) {
        await import('@/utils/getCookieToken')
          .then((module) => {
            const auth = module.getCookieToken();
            if (auth) {
              headers.set('Authorization', ` ${auth}`);
            }
          })
          .catch((err) => {
            console.error('Failed to load the getCookieToken module', err);
          });
      }

      return headers;
    }
  });
