import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex'
  },

  photo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: theme.spacing.md
  },

  name: {
    color: theme.black,
    fontSize: '18px',
    fontWeight: 600,
    wordBreak: 'break-word',
    marginBottom: theme.spacing.xs,
    lineHeight: '22px'
  },

  email: {
    color: theme.colors.brandGray[0],
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: theme.spacing.xs
  },

  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },

  groupTitle: {
    color: theme.colors.brandGray[0],
    fontSize: '12px'
  },

  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  }
}));
