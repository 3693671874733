import { FC, useEffect, useState } from 'react';
import { IColumnData, IRowData } from '../simple-table/types';

import { IconChevronRight } from '@tabler/icons';
import { ActionIcon, Box, Flex, useDirection } from '@mantine/core';
import { useStyles } from './styles';

interface ITrProps {
  columns: IColumnData[];
  row: IRowData;
  selection?: number[];
  handleRowClick?: (id: number) => void;
  nestingLevel?: number;
}

export const ExpandedTR: FC<ITrProps> = ({
  columns,
  row,
  selection,
  handleRowClick,
  nestingLevel = 1
}) => {
  const { dir } = useDirection();

  const { classes, cx } = useStyles({ nestingLevel });
  const children = row.Children ? (row.Children as IRowData[]) : [];
  const hasChildren = children.length > 0;

  const isSelected = !!selection?.includes(row.Id);
  const [showChildren, setShowChildren] = useState(row.IsOpen);

  useEffect(() => {
    setShowChildren(row.IsOpen);
  }, [row.IsOpen]);

  const clickRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const currentTarget = e.currentTarget;
    const target = e.target as HTMLElement;

    if (currentTarget.contains(target) && handleRowClick) {
      handleRowClick(Number(row?.Id));
    }
  };

  return (
    <>
      <tr
        data-id={row?.Id}
        onClick={clickRow}
        className={cx(classes.tr, {
          [classes.rowSelected]: isSelected,
          [classes.hasChildren]: hasChildren,
          [classes.noChildren]: !hasChildren
        })}
      >
        {columns.map((col, colIndex) => {
          const currentValue = col.render && col.render(row, selection);

          return (
            <td key={colIndex} className={classes.td}>
              {colIndex === 0 ? (
                hasChildren ? (
                  <Flex align="center">
                    <ActionIcon
                      sx={{
                        '&:hover': { backgroundColor: '#ffffff' }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowChildren(!showChildren);
                      }}
                    >
                      <IconChevronRight
                        size={18}
                        color="#98A2B3"
                        style={{
                          transform: showChildren ? `rotate(${dir === 'rtl' ? 90 : 90}deg)` : 'none'
                        }}
                      />
                    </ActionIcon>
                    <Box className={classes.contentContainer}>{currentValue}</Box>
                  </Flex>
                ) : (
                  currentValue
                )
              ) : (
                currentValue
              )}
            </td>
          );
        })}
      </tr>
      {hasChildren && showChildren
        ? children.map((child, index) => {
            return (
              <ExpandedTR
                key={index}
                columns={columns}
                row={child}
                handleRowClick={handleRowClick}
                nestingLevel={nestingLevel + 1}
              ></ExpandedTR>
            );
          })
        : null}
    </>
  );
};
