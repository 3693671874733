import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useBulkAddRolesMutation } from '@/entities/admin-app/user/api';
import { useLazyGetUsersQuery, usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { universalNotification } from '@/lib/utils/notification';
import { useTranslation } from 'react-i18next';
import { rolesApi } from '@/entities/admin-app/roles/api';
import { useAppSelector } from '../redux/redux';
import { useActions } from '../redux/action';
import { IRowData } from '@/components/simple-table/types';

export const useAddRoles = (
  selection: number[],
  setRowData?: Dispatch<SetStateAction<IRowData[]>>
) => {
  const { t } = useTranslation();

  const [fetchAddRoles, resultAddRoles] = useBulkAddRolesMutation();
  const { filters } = useAppSelector((state) => state.users);
  const [fetchGetUsers] = useLazyGetUsersQuery();
  const { setUsers } = useActions();

  const handleAddRoles = useCallback(
    async (selection: number[], roleIds: number[]) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];
      const roleIdsUnique = new Set(roleIds);

      try {
        const res = await fetchAddRoles({
          UserIds: numbersSelection,
          RoleIds: [...roleIdsUnique]
        });

        if (!Object.keys(res).includes('error')) {
          if (setRowData) {
            const { data } = await fetchGetUsers({
              Count: filters.Offset >= 20 ? filters.Offset + 20 : 20,
              Offset: 0,
              SortOrder: filters.SortOrder,
              SortField: filters.SortField,
              Filters: filters.Filters,
              Query: filters.Query
            });

            if (setRowData && data) {
              setUsers(data);
              setRowData(data.Users);
            }
          } else {
            clearApiCacheByTags(usersApi, ['Users']);
          }
          clearApiCacheByTags(rolesApi, ['Roles']);
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [selection]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultAddRoles;
    const _errorStatus = (error as any)?.data;

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successTitle: t('roles.addSuccess')
    });
  }, [resultAddRoles]);

  return { handleAddRoles };
};
