import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatisticsInitialFilters } from '@/types/enums/statistics';
import { IDiskSpace, IUserActivity, IUsersActions } from './types';
import {
  defaultAuditColumnsVisibility,
  initialStatisticFilter
} from '@/entities/admin-app/statistics/consts';
import { IAuditColumnsVisibilityType } from '@/containers/pages/statistics/types';
interface IStatistics {
  files: any[];
  total: number;
  filters: IStatisticsInitialFilters;
  selectedStatisticId: number;
  selected: number[];
  usersActionsList: {
    TotalCount: number;
    Items: IUsersActions[];
    filters: IStatisticsInitialFilters;
    columnVisibility: IAuditColumnsVisibilityType;
  };
  diskSpace: {
    Total: IDiskSpace;
    TotalCount: number;
    Items: IDiskSpace[];
    filters: IStatisticsInitialFilters;
  };
  userActivity: {
    Total: IUserActivity;
    TotalCount: number;
    Items: IDiskSpace[];
    filters: IStatisticsInitialFilters;
  };
}

const getSavedColumnVisibility = (
  localStorageKey: string,
  initialValues: IAuditColumnsVisibilityType
): IAuditColumnsVisibilityType => {
  try {
    const saved = localStorage?.getItem(`${localStorageKey}`);
    return saved ? JSON.parse(saved) : initialValues;
  } catch (error) {
    console.error('Error accessing cookies:', error);
    return initialValues;
  }
};

const initialState: IStatistics = {
  files: [],
  total: 0,
  filters: initialStatisticFilter,
  selectedStatisticId: 0, // for show UserInfoMain when selectedUserId !== 0
  selected: [],
  usersActionsList: {
    TotalCount: 0,
    Items: [],
    filters: initialStatisticFilter,
    columnVisibility: getSavedColumnVisibility(
      'UsersActionsColumnVisibility',
      defaultAuditColumnsVisibility
    )
  },
  diskSpace: {
    Total: {
      Name: '',
      Space: 0,
      DocsCount: 0,
      FilesCount: 0
    },
    TotalCount: 0,
    Items: [],
    filters: initialStatisticFilter
  },
  userActivity: {
    Total: {
      Date: '',
      Visitors: 0,
      Auths: 0,
      Documents: 0,
      Files: 0
    },
    TotalCount: 0,
    Items: [],
    filters: initialStatisticFilter
  }
};

export const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action: PayloadAction<IStatistics>) {
      state.files = action.payload.files;
      state.total = action.payload.total;
    },

    setUsersActionsList(state, action: PayloadAction<any>) {
      state.usersActionsList.Items = action.payload.Items;
      state.usersActionsList.TotalCount = action.payload.TotalCount;
    },

    setUsersActionsFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.usersActionsList.filters = action.payload;
    },

    setDiskSpace(state, action: PayloadAction<any>) {
      state.diskSpace.Items = action.payload.Items;
      state.diskSpace.TotalCount = action.payload.TotalCount;
      state.diskSpace.Total = action.payload.Total;
    },
    setDiskSpaceFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.diskSpace.filters = action.payload;
    },

    setUserActivity(state, action: PayloadAction<any>) {
      state.userActivity.Items = action.payload.Items;
      state.userActivity.TotalCount = action.payload.TotalCount;
      state.userActivity.Total = action.payload.Total;
    },
    setUserActivityFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.userActivity.filters = action.payload;
    },

    setSelectedAllStatistics(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    clearStatisticsSelected(state) {
      state.selected = [];
    },
    setUsersActionsColumnVisibility(state, action: PayloadAction<IAuditColumnsVisibilityType>) {
      try {
        localStorage.setItem('UsersActionsColumnVisibility', JSON.stringify(action.payload));
        state.usersActionsList.columnVisibility = action.payload;
      } catch (error) {
        console.error('Error saving in cookies:', error);
      }
    }
  }
});

export const statisticsActions = StatisticsSlice.actions;
export const statisticsReducer = StatisticsSlice.reducer;
