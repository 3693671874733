import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Loader, LoadingOverlay, SimpleGrid, Table, Text } from '@mantine/core';
import { DriveInfoPanelsEnum } from '@/types/enums/modules';
import { TableHeadList } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/table/head';
import { TableRowList } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/table/row';
import { useAppSelector } from '@hooks/redux/redux';
import { useGetQuotasQuery } from '@/entities/admin-app/quotas/api';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { useActions } from '@hooks/redux/action';
import { IFiltersEnumType } from '@/types/enums';
import { IQuota } from '@/entities/admin-app/quotas';
import { useGetColumns } from '@/containers/pages/filials/edit/components/tabs/disk-space/hooks/useGetColumns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconPlus } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { UsersQuotaModal } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/quota-modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { Modal } from '@components/modal';

interface IFilialQuotaProps {
  filial: IFilialsInfoCommon;
}
export const UsersQuotaList = ({ filial }: IFilialQuotaProps) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const scrollRef = useRef<any>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { setQuotas, setQuotasFilters, setSelectedQuota } = useActions();
  const { quotas, totalQuotas, quotasFilters, selectedQuota } = useAppSelector(
    (state) => state.quotas
  );
  const { data, /*error,*/ isLoading } = useGetQuotasQuery({
    Count: quotasFilters.Count,
    Offset: quotasFilters.Offset,
    LoadUseCount: true,
    FilterConditions: filial
      ? [
          {
            Type: IFiltersEnumType.FilialFilter,
            NumValues: [filial?.Id]
          }
        ]
      : []
  });

  const onEdit = (quota: IQuota) => {
    setSelectedQuota(quota);
    setIsModalOpened(true);
  };
  const { columns } = useGetColumns({ onEdit: onEdit });

  useEffect(() => {
    if (!data) {
      return;
    }
    const quotasData = quotasFilters.Offset > 0 ? [...quotas, ...data.Quotas] : data.Quotas;
    setQuotas({ ...data, Quotas: quotasData });
  }, [data]);

  const handleLoadMore = () => {
    setQuotasFilters({
      ...quotasFilters,
      Offset: quotasFilters.Offset + quotasFilters.Count
    });
  };

  const resetQuotaFilters = () => {
    scrollRef.current.el.scrollTo(0, 0);
    setQuotasFilters({
      Count: 10,
      Offset: 0
    });
  };

  useEffect(() => {
    if (quotasFilters.Offset === 0) {
      scrollRef.current.el.scrollTo(0, 0);
    }
  }, [quotasFilters]);

  const handleScroll = () => {
    setScrolled(true);
  };

  return (
    <Box className={classes.section} data-section={DriveInfoPanelsEnum.quotas}>
      <Text className={classes.title}>{t('filials.diskSpace.usersQuota')}</Text>
      <InfiniteScroll
        ref={scrollRef}
        dataLength={quotas?.length}
        next={handleLoadMore}
        hasMore={totalQuotas > quotas?.length}
        loader={
          quotas?.length ? (
            <Box className={classes.infiniteScrollMoreData}>
              <Loader size="sm" />
            </Box>
          ) : null
        }
        className={classes.infiniteScroll}
        height={quotas?.length < 5 ? 'auto' : '400px'}
        onScroll={handleScroll}
      >
        <Table horizontalSpacing="md" verticalSpacing="xs" mt="sm">
          <thead className={cx(classes.tableHeader, { [classes.scrolled]: scrolled })}>
            <TableHeadList columns={columns} />
          </thead>
          <tbody>{quotas?.length > 0 && <TableRowList rows={quotas} columns={columns} />}</tbody>
        </Table>
      </InfiniteScroll>
      <Button
        className={classes.addButton}
        size="sm"
        mt="sm"
        variant="outline"
        rightSection={<IconPlus color={themeColors.brandGray[0]} />}
        onClick={() => {
          setIsModalOpened(true);
        }}
      >
        {t('filials.diskSpace.addQuota')}
      </Button>

      <SimpleGrid mt="sm" mb="sm">
        <Text fz="xs" color="#667085">
          {t('filials.diskSpace.usersQuotaDescriptions')}
        </Text>
      </SimpleGrid>
      <LoadingOverlay visible={isLoading} />
      <Modal
        size="450px"
        opened={isModalOpened}
        title={t(`filials.diskSpace.${selectedQuota ? 'editUsersQuota' : 'createUsersQuota'}`)}
        onClose={() => {
          setSelectedQuota(null);
          setIsModalOpened(false);
          resetQuotaFilters();
        }}
      >
        <UsersQuotaModal
          onClose={() => {
            setSelectedQuota(null);
            setIsModalOpened(false);
            resetQuotaFilters();
          }}
          quota={selectedQuota}
          CustomerId={filial?.Id}
        />
      </Modal>
    </Box>
  );
};
