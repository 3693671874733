import { Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconShieldLock } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

interface ISafetySectionProps {
  filialId?: number;
}

export const SafetySection: FC<ISafetySectionProps> = ({ filialId }) => {
  const { t } = useTranslation();

  const safetyMenu: IListItemProps = {
    withCheck: false,
    items: [
      {
        name: t('filialSecurity.ipSecurity'),
        link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.common.security}`
      },
      {
        name: t('filialSecurity.passwordSecurity'),
        link: `${ROUTES.filials.fullPath}/${filialId}/${ROUTES.filials.edit.pages.common.security}`
      }
    ]
  };

  return (
    <CardContainer sectionName={t('profile.safety')} Icon={IconShieldLock}>
      <Stack gap="7px" sx={{ width: '100%' }}>
        <ListItem items={safetyMenu.items} withCheck={safetyMenu.withCheck} />
      </Stack>
    </CardContainer>
  );
};
