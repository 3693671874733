import {
  Dispatch,
  FC,
  SetStateAction,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Button, Loader, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { IGroup } from '@/entities/admin-app/user';
import { IListItem } from '@/types';
import { useGetRolesForUserQuery } from '@/entities/admin-app/directories/api';
import { IRole } from '@/entities/admin-app/directories';
import { Search } from '../../../search/search-input';
import { ScrollContainer } from '../../../scroll-container';
import { GroupItem } from '../group-item';
import { useDebounce } from '@hooks/useDebounce';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/redux/redux';
import { IFiltersEnumType } from '@/types/enums';
import { NoDataText } from '@/components/not-found/components/no-data-text';

import { getUserRoleFilters } from '@/containers/pages/roles/utils';

interface ISelectGroupProps {
  setActiveModal: Dispatch<SetStateAction<string>>;
  setGroups: (data: IGroup[]) => void | Dispatch<SetStateAction<IGroup[]>>;
  groups: IGroup[];
  handleClose: () => void;
}

const selectItems = (list: IListItem[], value?: number) => {
  return list.map((item) => {
    const currentValueChecked = item.value === value ? !item.checked : item.checked;
    return {
      ...item,
      checked: currentValueChecked
    };
  });
};

export const SelectGroup: FC<ISelectGroupProps> = ({
  // setActiveModal,
  setGroups,
  groups,
  handleClose
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [search, setSearch] = useState<string>('');
  const debounced = useDebounce(search, 500);
  const [offsetObj, setOffset] = useState({ count: 10, offset: 0 });
  const listRef = useRef<IListItem[]>([]);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  const { data, isSuccess } = useGetRolesForUserQuery({
    Count: offsetObj.count,
    Offset: offsetObj.offset,
    Query: debounced,
    FilterConditions: [
      {
        Type: IFiltersEnumType.RoleTypeFilter,
        NumValues: getUserRoleFilters(currentUser)
      }
    ]
  });

  const [list, setList] = useState<IListItem[]>([]);

  const [listData, setListData] = useState<IListItem[]>([]);
  // const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false);

  const selectionGroups = useMemo(() => {
    const checkedGroups = listData.filter((item) => item.checked);

    return Boolean(checkedGroups.length);
  }, [listData]);

  const handleClickGroupItem = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const value = Number(event.currentTarget.dataset.value);

      listRef.current = selectItems(listRef.current, value);

      setListData((prevState) => selectItems(prevState, value));
      setList((prevState) => selectItems(prevState, value));
    },
    [groups]
  );

  // const selectAll = useCallback(() => {
  //   setHasSelectedAll(true);
  //   setListData((prevState) => prevState.map((item) => ({ ...item, checked: true })));
  //   setList((prevState) => prevState.map((item) => ({ ...item, checked: true })));
  // }, []);
  //
  // const resetAll = useCallback(() => {
  //   setHasSelectedAll(false);
  //   setListData((prevState) => prevState.map((item) => ({ ...item, checked: false })));
  //   setList((prevState) => prevState.map((item) => ({ ...item, checked: false })));
  // }, []);
  //
  // const goNext = () => {
  //   setActiveModal(SelectGroupEnum.addNewGroup);
  // };

  const handleLoadMore = () => {
    const offset =
      data?.TotalCount && offsetObj.offset + 10 > data.TotalCount
        ? data.TotalCount
        : offsetObj.offset + 10;
    setOffset({ count: 10, offset: offset });
  };

  const addGroups = useCallback(() => {
    if (listData.length) {
      const filtredListData = listData
        .filter((item) => item.checked)
        .map((el) => ({ Name: String(el.label), Id: Number(el.value) }));

      setGroups(filtredListData);
    }
  }, [listData]);

  useEffect(() => {
    if (data?.Items?.length && isSuccess) {
      const existingGroupIds = groups?.map((item) => item.Id);

      const convertData = data.Items.map((item: IRole) => ({
        label: item.Name,
        value: item.Id,
        checked: false,
        disabled: existingGroupIds?.includes(item.Id),
        counter: item.Counter
      }));
      const convertTotalData =
        offsetObj.offset > 0 ? [...listRef.current, ...convertData] : convertData;
      listRef.current = convertTotalData;
      setListData(convertTotalData);
      setList(convertTotalData);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      const filterSearch = listRef.current?.filter((item) =>
        item.label.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
      );

      setList(filterSearch);
    } else {
      setList(listRef.current);
    }
    setOffset({ count: 10, offset: 0 });
  }, [search]);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('selectGroup.searchRole')}
        />
        <ScrollContainer sx={{ height: '300px', width: '100%' }}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={list?.length}
            next={handleLoadMore}
            height={300}
            hasMore={data ? data?.TotalCount > list?.length : false}
            endMessage={!list?.length && <NoDataText />}
            loader={
              <>
                {Boolean(list?.length) && (
                  <Box className={classes.infiniteScrollMoreData}>
                    <Loader size="sm" />
                  </Box>
                )}
              </>
            }
            className={classes.infiniteScroll}
          >
            <>
              {list?.map((item: IListItem) => {
                return (
                  <GroupItem
                    key={item.value}
                    checked={item.checked || item.disabled}
                    disabled={item.disabled}
                    label={item.label}
                    handleChange={handleClickGroupItem}
                    value={Number(item.value)}
                    withCheckbox
                  />
                );
              })}
            </>
          </InfiniteScroll>
        </ScrollContainer>
      </Box>

      <Box className={classes.footer}>
        <Button size="sm" radius={8} w="49%" disabled={!selectionGroups} onClick={addGroups}>
          {t('add')}
        </Button>

        <UnstyledButton ml={8} className={classes.cancelBtn} w="49%" onClick={handleClose}>
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </Box>
  );
};
