import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Checkbox } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { useChangeRoles } from '@hooks/users/useChangeRoles';
import { useChangeStatuses } from '@hooks/users/useChangeStatuses';
import { useResetPassword } from '@hooks/users/useResetPassword';
import { BadgeMenu } from './badge-menu';
import { useStyles } from './styles';
import { IActionItem, IControlProps } from './types';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { IUserTableData } from '@/entities/admin-app/users';
import { useAppSelector } from '@hooks/redux/redux';
import { downloadCsv } from '@/lib/files';
import { UserStatusEnum } from '@/types/enums/user';
import { UsersActionTableEnum } from '@/types/enums/users';
import { useGetActionList } from '../../hooks/useGetActionList';
import { SelectGroupModal } from '@components/modal/select-group';
import { IListItem } from '@/types';
import { IGroup } from '@/entities/admin-app/user';
import { useAddRoles } from '@hooks/users/useAddRoles';
import { useChangeFilial } from '@hooks/users/useChangeFilial';
import { DeleteUser } from '@/containers/pages/users/components/modals/delete';
import { FilialsControl } from '@/containers/pages/users/components/control/ filials-control';
import { VerifyEmailUsers } from '../modals/send-email';
import { SendEmailActivationModal } from '@/containers/pages/users/components/modals/send-activation';
import { useTranslation } from 'react-i18next';
import { IRole } from '@/entities/admin-app/directories';
import { TableColumnsAction } from '@components/simple-table/TableColumnsActions';
import { useActions } from '@hooks/redux/action';

export const Control: FC<IControlProps> = ({
  selection,
  rowData,
  toggleAll,
  columns,
  setColumnsData,
  setRowData,
  simpleColumns,
  scrollRef
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const actionList = useGetActionList();
  const { users } = useAppSelector((state: any) => state.users);
  const [fetchGetUsers] = useLazyGetUsersQuery();
  const { filters: usersFilter, total } = useAppSelector((state) => state.users);
  const { setColumnVisibility } = useActions();
  const [type, setType] = useState<string>('');
  const [checkboxValues, setCheckboxValues] = useState<number[]>([]);

  const { changeStatuses } = useChangeStatuses(setRowData);
  const { changeRoles } = useChangeRoles(setRowData, selection);
  const { changeFilial } = useChangeFilial(setRowData, selection);
  const { handleAddRoles } = useAddRoles(selection, setRowData);
  // const { sendEmailActivation } = useEmailActivation();
  const { resetPassword } = useResetPassword();
  const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);
  const [openSendEmailActivationModal, setOpenSendEmailActivationModal] = useState(false);
  const [openAddRolesModal, setOpenAddRolesModal] = useState(false);
  const [openDeleteUsersModal, setOpenDeleteUsersModal] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState<IListItem>({} as IListItem);
  const [groups, setGroups] = useState<IRole[]>([]);

  const handleAddRole = (roles: IGroup[]) => {
    const rolesIds = roles?.map((role) => role.Id);
    handleAddRoles(selection, rolesIds);
    setOpenAddRolesModal(false);
  };

  const closeAddRolesModal = () => {
    setOpenAddRolesModal(false);
  };

  const handleOpenAddRolesModal = () => {
    setOpenAddRolesModal(true);
  };
  const handleOpenDeleteUsersModal = () => {
    setOpenDeleteUsersModal(true);
  };

  const handleCloseDeleteUsersModal = () => {
    setOpenDeleteUsersModal(false);
  };

  const handleChangeCheckbox = useCallback((event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.dataset.checkbox ?? '';
    setCheckboxValues((prevState) => [...prevState, Number(value)]);
  }, []);

  const getAllUserByFilters = async (): Promise<IUserTableData[]> => {
    const { data } = await fetchGetUsers({
      Count: total,
      Offset: 0,
      SortOrder: 0,
      SortField: 0,
      Query: usersFilter.Query,
      FilterConditions: usersFilter.FilterConditions
    });

    if (data === undefined) {
      return [];
    }

    return data.Users;
  };

  const handleClickListItem = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const value = event.currentTarget.dataset.value ?? '';
      const valueNumber = Number(value);

      if (type === UsersActionTableEnum.export) {
        if (value === 'export') {
          downloadCsv(rowData, simpleColumns);
        } else if (value === 'export-all') {
          const allData = await getAllUserByFilters();
          downloadCsv(allData, simpleColumns);
        }
      } else {
        if (selection.length) {
          switch (type) {
            case UsersActionTableEnum.role:
              await changeRoles(selection, checkboxValues);
              break;
            case UsersActionTableEnum.filial:
              await changeFilial(selection, valueNumber);
              break;
            case UsersActionTableEnum.department:
              break;
            case UsersActionTableEnum.status:
              await changeStatuses(selection, valueNumber);
              break;
            default:
              break;
          }
        } else {
          showNotification({
            title: t('roles.noSelectedUsers'),
            message: '',
            autoClose: true,
            color: 'yellow'
          });
        }
      }
    },
    [selection, type, checkboxValues, rowData, simpleColumns]
  );

  const handleClickBadge = useCallback(
    async (event: React.SyntheticEvent<HTMLDivElement>) => {
      const type = event.currentTarget.dataset.type ?? '';
      setType(type);

      if (selection.length) {
        switch (type) {
          case UsersActionTableEnum.sendActivationLinkAgain:
            // await sendEmailActivation(selection);
            setOpenSendEmailActivationModal(true);
            break;
          case UsersActionTableEnum.writeLetter:
            setOpenVerifyEmailModal(true);
            break;
          case UsersActionTableEnum.removedUser:
            await changeStatuses(selection, UserStatusEnum.Deleted);
            break;
          case UsersActionTableEnum.resetPassword:
            await resetPassword(selection);
            break;
          case UsersActionTableEnum.addRoles:
            handleOpenAddRolesModal();
            break;
          case UsersActionTableEnum.delete:
            // navigate(ROUTES.users.delete.fullPath);
            handleOpenDeleteUsersModal();
            break;
          default:
            break;
        }
      } else {
        if (
          [
            UsersActionTableEnum.sendActivationLinkAgain,
            UsersActionTableEnum.writeLetter,
            UsersActionTableEnum.removedUser,
            UsersActionTableEnum.resetPassword,
            UsersActionTableEnum.addRoles,
            UsersActionTableEnum.delete
          ].includes(type as UsersActionTableEnum)
        )
          showNotification({
            title: t('roles.noSelectedUsers'),
            message: '',
            autoClose: true,
            color: 'yellow'
          });
      }
    },
    [selection]
  );

  useEffect(() => {
    if (selection.length === 1) {
      const selectedUser = users.find((user: IUserTableData) => user.Id === selection[0]);
      setGroups(selectedUser?.Roles || []);
    } else {
      setGroups([]);
    }
  }, [selection, users]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.leftSection}>
          {selection && (
            <Checkbox
              size="sm"
              className={classes.checkbox}
              onChange={toggleAll}
              checked={Boolean(
                rowData.length > 0 && selection.length > 0 && selection.length === rowData.length
              )}
              indeterminate={selection.length > 0 && selection.length !== rowData.length}
              data-cy="selectAllCheckbox"
            />
          )}

          {actionList?.map((item: IActionItem, index: number) => {
            return item.value === UsersActionTableEnum.filial ? (
              <FilialsControl
                key={index}
                handleClickListItem={handleClickListItem}
                handleClick={handleClickBadge}
                disabled={!selection.length}
                item={item}
              />
            ) : (
              <BadgeMenu
                key={index}
                item={item}
                handleClickListItem={handleClickListItem}
                handleClick={handleClickBadge}
                handleChangeCheckbox={handleChangeCheckbox}
                ddmClassNames={{
                  dropdown: classes.menu
                }}
                ddmWidth="190px"
                disabled={!selection.length}
              />
            );
          })}
        </Box>
        <Box className={classes.rightSection}>
          <TableColumnsAction
            setColumnsData={setColumnsData}
            list={columns}
            setColumnVisibility={setColumnVisibility}
          />
        </Box>
      </Box>
      {openVerifyEmailModal && (
        <VerifyEmailUsers
          open={openVerifyEmailModal}
          setOpen={setOpenVerifyEmailModal}
          selectedUsers={selection}
        />
      )}
      {openSendEmailActivationModal && (
        <SendEmailActivationModal
          open={openSendEmailActivationModal}
          setOpen={setOpenSendEmailActivationModal}
          openSendEmail={setOpenSendEmailActivationModal}
          selectedUsers={selection}
        />
      )}
      <SelectGroupModal
        isOpened={openAddRolesModal}
        title={t('profile.addRole')}
        onClose={closeAddRolesModal}
        setSelectedGroup={setSelectedRoles}
        selectedGroup={selectedRoles}
        setGroups={handleAddRole}
        groups={groups}
      />
      {openDeleteUsersModal && (
        <DeleteUser
          isOpened={openDeleteUsersModal}
          selectedUsers={selection}
          handleClose={handleCloseDeleteUsersModal}
          setRowData={setRowData}
          scrollRef={scrollRef}
        />
      )}
    </>
  );
};
