import React, { FC, useEffect } from 'react';
import { Button, Flex, Text, NumberInput, Checkbox, Tooltip } from '@mantine/core';
import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { useStyles } from '../../../../../styles';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { useTranslation } from 'react-i18next';
import { isSaaS } from '@/lib/utils/access';

interface ISecurityPassword {
  passwordLength: number;
  useCapital: boolean;
  UseLowLetter: boolean;
  useDigital: boolean;
  useCharacters: boolean;
  UseAllowedSpecialCharacters: boolean;
}

export const PasswordStrength: FC = () => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();

  const { filial } = useAppSelector((state) => state.filials);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      passwordLength: 8,
      useCapital: false,
      UseLowLetter: false,
      useDigital: false,
      useCharacters: false,
      UseAllowedSpecialCharacters: false
    },
    validate: (values): FormErrors => getValidateErrors(schema, values)
  });

  const schema = z.object({
    passwordLength: z
      .number({
        invalid_type_error: t('filialSecurity.errors.passwordMinMaxLength')
      })

      .positive({
        message: t('filialSecurity.errors.positive')
      })
      .min(8, { message: t('filialSecurity.errors.passwordMinMaxLength') })
      .max(30, { message: t('filialSecurity.errors.passwordMinMaxLength') }),
    useCapital: z.boolean(),
    useDigital: z.boolean(),
    useCharacters: z.boolean(),
    UseLowLetter: z.boolean(),
    UseAllowedSpecialCharacters: z.boolean()
  });

  const saveSettings = useSaveSettings();

  useEffect(() => {
    const { PasswordSecurity } = filial?.Settings || {};
    if (!PasswordSecurity) {
      return;
    }

    const formData = {
      passwordLength: PasswordSecurity?.MinLength || 8,
      useCapital: PasswordSecurity?.UseCapitalLetters || false,
      UseLowLetter: PasswordSecurity?.UseLowLetter || false,
      useDigital: PasswordSecurity?.UseDigital || false,
      useCharacters: PasswordSecurity?.UseSpecialCharacters || false,
      UseAllowedSpecialCharacters: PasswordSecurity?.UseAllowedSpecialCharacters || false
    };

    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = (values: ISecurityPassword) => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.PasswordSecurity, {
      PasswordSecurity: {
        MinLength: values.passwordLength,
        UseCapitalLetters: values.useCapital,
        UseLowLetter: values.UseLowLetter,
        UseDigital: values.useDigital,
        UseSpecialCharacters: values.useCharacters,
        UseAllowedSpecialCharacters: values.UseAllowedSpecialCharacters
      }
    });
  };

  return (
    <Flex
      className={classes.section}
      align="flex-start"
      direction="column"
      pos="relative"
      data-section={FilialInfoPanelsEnum.passwordSecurity}
    >
      <Text className={classes.title}>{t('filialSecurity.passwordStrengthTitle')}</Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex align="flex-start" direction="column">
          <NumberInput
            className={classes.textFieldInput}
            label={t('filialSecurity.passwordMinLength')}
            hideControls
            {...form.getInputProps(`passwordLength`)}
          />
          <Checkbox
            sx={{ marginTop: '12px' }}
            className={classes.checkboxItem}
            size="xs"
            {...form.getInputProps('UseLowLetter', { type: 'checkbox' })}
            label={t('filialSecurity.useLowerCaseLetters')}
          />
          <Checkbox
            className={classes.checkboxItem}
            size="xs"
            {...form.getInputProps('useCapital', { type: 'checkbox' })}
            label={t('filialSecurity.useCapitalLetters')}
          />
          <Checkbox
            className={classes.checkboxItem}
            size="xs"
            {...form.getInputProps('useDigital', { type: 'checkbox' })}
            label={t('filialSecurity.useDigital')}
          />
          <Checkbox
            className={classes.checkboxItem}
            size="xs"
            {...form.getInputProps('useCharacters', { type: 'checkbox' })}
            label={t('filialSecurity.useSpecialCharacters')}
          />

          <Checkbox
            className={classes.checkboxItem}
            size="xs"
            {...form.getInputProps('UseAllowedSpecialCharacters', { type: 'checkbox' })}
            label={
              <Flex wrap="wrap">
                <Text fz="sm" mr="xs" sx={{ lineHeight: '16px' }}>
                  {t('filialSecurity.limitUse')}
                </Text>
                <Tooltip
                  sx={{
                    background: '#FFF',
                    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.07)',
                    borderRadius: theme.spacing.xs,
                    padding: '8px'
                  }}
                  label={t('filialSecurity.specialCharactersTooltip')}
                  c={theme.colors.dark[8]}
                >
                  <Text
                    fz="sm"
                    sx={{
                      textDecoration: 'underline dotted',
                      lineHeight: '16px',
                      cursor: 'pointer'
                    }}
                  >
                    {t('filialSecurity.specialCharacters')}
                  </Text>
                </Tooltip>
              </Flex>
            }
          />
        </Flex>

        <Text className={classes.description} mt="sm" mb="md">
          {t(
            `filialSecurity.${
              isSaaS() ? 'passwordStrengthDescriptionSaaS' : 'passwordStrengthDescriptionBox'
            }`
          )}
        </Text>
        <Button
          size="sm"
          radius={8}
          mr="sm"
          type="submit"
          disabled={!form.isValid() || !form.isDirty()}
        >
          {t('save')}
        </Button>
      </form>
    </Flex>
  );
};
