import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  icon: {
    marginTop: '20px'
  },

  desc: {
    textAlign: 'center',
    color: theme.colors.gray[6],
    lineHeight: '22px',
    marginBottom: '5px'
  },
  controls: {
    '& .mantine-InputWrapper-error': {
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '14px',
      position: 'relative'
    }
  },
  subtleButton: {
    fontWeight: 100,
    padding: 0,
    alignSelf: 'flex-end',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  }
}));
