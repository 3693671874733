import { ChangeEvent, FC, ReactNode } from 'react';
import { Input, InputProps } from '@mantine/core';
import { ISearchStylesParams, useStyles } from './styles';
import { IconX } from '@tabler/icons';

export interface ISearchProps extends InputProps {
  placeholder?: string;
  props?: InputProps;
  fullSize?: ISearchStylesParams['fullSize'];
  setSearch: (value: string) => void;
  value: string;
  hideClearIcon?: boolean;
  icon?: ReactNode;
}

export const Search: FC<ISearchProps> = ({
  placeholder = 'Search...',
  className,
  fullSize,
  setSearch,
  hideClearIcon,
  icon,
  value,
  ...props
}) => {
  const { classes, cx } = useStyles({ fullSize });

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const clearValue = () => {
    setSearch('');
  };

  return (
    <Input
      data-lpignore="true"
      autoComplete="new-password"
      type="search"
      className={cx(classes.search, className)}
      leftSection={icon}
      placeholder={placeholder}
      onChange={onSearchChange}
      value={value}
      rightSectionPointerEvents="all"
      rightSection={
        !hideClearIcon &&
        value && <IconX size="18" className={classes.clearIcon} onClick={clearValue} />
      }
      {...props}
    />
  );
};
