import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  filtersListWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    gap: '20px'
  }
}));
