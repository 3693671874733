import { useEffect, useState } from 'react';
import { useGetDefaultRolesFiltersQuery } from '@/entities/admin-app/roles/api';
import { IFilterMenu } from '@/types/api';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { IFiltersEnumType } from '@/types/enums';
import { useTranslation } from 'react-i18next';

export const useGetRolesFilters = () => {
  const { t } = useTranslation();
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultRolesFiltersQuery(20);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (defaultFiltersData && defaultFiltersSuccess) {
      const convertDefaultFilters: IFilterMenu[] = defaultFiltersData
        ?.map((item) => ({
          ...item,
          label:
            item.Type in IFiltersEnumType
              ? t(`filters.${filtersLabelsKeys[item.Type]}`)
              : t('filters.title'),
          Items: item.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
          hideSearch: item.Type === IFiltersEnumType.RoleTypeFilter ? true : false
        }))
        .sort((a, b) => a.Type - b.Type);
      setFiltersMenuList(convertDefaultFilters);
    }
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
