import { createStyles } from '@mantine/emotion';

export interface IThStylesParams {
  hideSort?: boolean;
}

export const useStyles = createStyles((theme, { hideSort }: IThStylesParams) => ({
  th: {
    padding: '0 !important',
    height: '44px',
    borderBottom: 'none !important'
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    cursor: hideSort ? 'default' : 'pointer'
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21
  },

  hideColumn: {
    display: 'none'
  }
}));
