import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  searchWrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 24px',
    width: '100%',
    margin: '24px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [utils.largerThan('xs')]: {
      alignItems: 'center'
    }
  },
  search: {
    marginRight: '27px',
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    },
    '& .mantine-Input-section': {
      marginLeft: '15px'
    },
    [utils.smallerThan('xs')]: {
      marginBottom: 15
    }
  }
}));
