import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import { Button, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';

export const CreateButton = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 1200px)');

  return (
    <Button
      size="md"
      leftSection={<IconPlus />}
      type="button"
      className={classes.createButton}
      onClick={() => navigate(`/create-user`)}
      data-cy="createNewUserButton"
    >
      <Text fw="600">{isMobile ? '' : t('create')}</Text>
    </Button>
  );
};
