import { Button, LoadingOverlay, Select, SimpleGrid } from '@mantine/core';
import { FC, useContext, useEffect } from 'react';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { FormErrors, useForm } from '@mantine/form';
import { ILanguageSettings } from '@/entities/admin-app/user';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateLanguageSettings } from '@/containers/pages/user/schemes/validate';
import { useUpdateInfo } from '@hooks/users/useUpdateUser';
import { useTranslation } from 'react-i18next';
import { EditUserInfoContext } from '@/containers/pages';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useLocalStorage } from '@mantine/hooks';
import { useAppSelector } from '@hooks/redux/redux';
import { useStyles } from '../../../../styles';

const Language: FC = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [userLanguage, setUserLanguage] = useLocalStorage<string>({
    key: 'user-language'
  });

  const languageOptionsList = [
    { value: 'ru', label: t('profile.languageOptions.ru') },
    {
      value: 'en',
      label: t('profile.languageOptions.en')
    }
  ];

  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);
  const User = useAppSelector((state) => state?.userInfo?.User);

  const isCurrentUser = currentId === User?.Id;

  const { saveUpdate, isLoading } = useUpdateInfo();

  const userLanguageForm = useForm<ILanguageSettings>({
    initialValues: {
      Language: ''
    },
    validateInputOnChange: true,
    validate: (values: ILanguageSettings): FormErrors =>
      getValidateErrors(validateLanguageSettings(t), values)
  });

  const errors = userLanguageForm.errors;
  const isDirtyForm = userLanguageForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    const fromData: ILanguageSettings = {
      Language: isCurrentUser
        ? userLanguage
        : userInfoCommon.Language
        ? userInfoCommon.Language
        : 'ru'
    };
    userLanguageForm.setValues(fromData);
    userLanguageForm.resetDirty(fromData);
  }, [userLanguage]);

  const handleSubmit = async (values: any) => {
    if (isCurrentUser) {
      setUserLanguage((prevState) => (prevState === values.Language ? prevState : values.Language));
      await i18n.changeLanguage(values.Language);
    }
    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Language,
      Data: { [ProfileSectionEnum.Language]: values, Id: currentId }
    });
  };

  return (
    <form onSubmit={userLanguageForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.languageSettings')}>
        <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
          <Select
            className={classes.select}
            label={t('profile.language')}
            placeholder={t('profile.language')}
            data={languageOptionsList}
            {...userLanguageForm.getInputProps('Language')}
          />
        </SimpleGrid>

        <Button
          type="submit"
          size="sm"
          radius={8}
          disabled={isDisabled}
          mt="md"
          style={{ padding: '0 20px' }}
        >
          {t('profile.save')}
        </Button>

        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};

export default Language;
