import { Flex, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import { FC } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { useNavigate } from 'react-router-dom';

interface ItemProps {
  name: string;
  link?: string;
  openModal?: () => void;
  valid?: boolean;
  rightSection?: string;
}

export interface IListItemProps {
  withCheck: boolean;
  items: ItemProps[];
}

export const ListItem: FC<IListItemProps> = ({ withCheck, items }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleRedirect = async ({ link, name, openModal }: ItemProps) => {
    if (link) {
      scrollTo(0, 0);
      navigate(link);
    }
    if (openModal) {
      openModal();
    }

    if (name === t('filialSecurity.passwordSecurity') && link) {
      const checkElement = () => {
        const element = document.querySelector(
          `[data-section=${FilialInfoPanelsEnum.passwordLimit}]`
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          setTimeout(checkElement, 100);
        }
      };
      setTimeout(checkElement, 100);
    }
  };

  return (
    <>
      {items.map((item: ItemProps, index: number) => {
        return (
          <Flex key={index} justify="space-between" align="center">
            <Text onClick={() => handleRedirect(item)} className={classes.itemName}>
              {item.name}
            </Text>
            {withCheck ? (
              <ThemeIcon
                mr="6px"
                radius="xl"
                color={item.valid ? '#51CF66' : '#FA5252'}
                size="20px"
              >
                {item.valid ? <IconCheck size="12" stroke="4" /> : <IconX size="12" stroke="4" />}
              </ThemeIcon>
            ) : null}
            {item.rightSection && (
              <Text fz="sm" fw="500">
                {item.rightSection}
              </Text>
            )}
          </Flex>
        );
      })}
    </>
  );
};
