import { Box, Button, Group, Text } from '@mantine/core';
import iconSuccess from '@assets/icon-mail-success.svg';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
export const SuccessResetContent = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.responseContent}>
      <img src={iconSuccess} alt="icon" className={classes.icon} />
      <Text className={classes.desc} ta="center">
        {t('passwordReset.passwordChangedSuccessfully')}
      </Text>
      <Group justify="center" gap="xs" w="100%">
        <Button className={classes.button} size="lg" component="a" href="/">
          {t('backMain')}
        </Button>
      </Group>
    </Box>
  );
};
