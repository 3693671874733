import { alpha } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  link: {
    border: 'none',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}`,
    marginBottom: '4px',
    backgroundColor: alpha(theme.colors.gray[2], 0.2),
    fontSize: theme.fontSizes.sm,
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    borderRadius: '8px',
    width: '100%',
    cursor: 'pointer',

    '& input, label': {
      cursor: 'pointer'
    },

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[7],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[2],
        color: theme.black
      }
    },

    '& .mantine-Checkbox-root, .mantine-Checkbox-body, .mantine-Checkbox-labelWrapper': {
      width: '100%'
    },

    '& .mantine-Checkbox-label': {
      paddingLeft: theme.spacing.xs
    },

    '& .mantine-CheckboxIndicator-indicator': {
      cursor: 'pointer'
    }
  },

  counter: {
    fontSize: '12px',
    [utils.dark]: {
      color: theme.white
    },
    [utils.light]: {
      color: theme.black
    },
    marginLeft: '12px'
  },

  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: theme.spacing.sm
  },

  checkbox: {
    cursor: 'pointer',
    fontWeight: 400,
    width: '220px',
    paddingRight: theme.spacing.xs,
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
}));
