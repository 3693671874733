import { useDeleteRoleMutation } from '@/entities/admin-app/user/api';
import { usersApi } from '@/entities/admin-app/users/api';
import { IUserTableData } from '@/entities/admin-app/users';
import { clearApiCacheByTags } from '@/entities/helpers';
import { IFiltersEnumType } from '@/types/enums';
import { showNotification } from '@mantine/notifications';
import { useAppSelector } from '../redux/redux';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { Dispatch, useEffect } from 'react';
import { openContextModal } from '@mantine/modals';
import { Box } from '@mantine/core';
import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';
import { rolesApi } from '@/entities/admin-app/roles/api';

export const useDeleteRole = (setUsers: Dispatch<IUserTableData[]>) => {
  const { t } = useTranslation();
  const [deleteUserRole, { isSuccess }] = useDeleteRoleMutation();
  const { filters } = useAppSelector((state) => state.users);
  const [fetchGetUsers] = useLazyGetUsersQuery();

  const handleDeleteRole = async (userId: number, roleId: number) => {
    try {
      const res = await deleteUserRole({ UserId: userId, RoleId: roleId });
      if (!Object.keys(res).includes('error')) {
        clearApiCacheByTags(usersApi, ['Users']);
        clearApiCacheByTags(rolesApi, ['Roles']);
        const { data } = await fetchGetUsers({
          Count: filters.Count,
          Offset: 0,
          SortOrder: filters.SortOrder,
          SortField: filters.SortField,
          Filters: filters.Filters,
          Query: filters.Query,
          FilterConditions: [
            {
              Type: IFiltersEnumType.RoleFilter,
              NumValues: [roleId]
            }
          ]
        });

        if (data) {
          setUsers(data.Users);
        }
      }
    } catch (err: any) {
      console.warn('Error deleting role', err);
    }
  };

  const openDeleteRole = (userId: number, roleId: number) => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: (
          <Box mb={24}>
            {t('roles.deleteWarningText')}
            {/*Вы уверены что хотите удалить роль? Пользователь с данной ролью может потерять доступ к*/}
            {/*данным, к которым были предоставлены права.*/}
          </Box>
        ),
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%',
            '& .mantine-Button-label': {
              color: 'black'
            }
          }
        },
        labels: { confirm: t('continue'), cancel: t('cancel') },
        onConfirm: () => handleDeleteRole(userId, roleId)
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        title: t('roles.deleteSuccess'),
        message: '',
        autoClose: true,
        color: 'green'
      });
    }
  }, [isSuccess]);

  return { openDeleteRole };
};
