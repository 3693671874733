import { useNavigate } from 'react-router-dom';
import { Button, Flex, Group, Text } from '@mantine/core';
import { MenuButton } from './components/menu-button';
import { useStyles } from './styles';
import { ROUTES } from '../../../../../routes/constants';
import { Modal } from '../../../../../../components/modal';
import { USER_LINKS_LIST } from '../../../../../../mock-data/user-links';
import { CalendarIcon, LockIcon } from '../../../../../../assets/icons';
import { Controls } from '../../../../../../components/modal/components/controls';

export const LinkList = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleClose = () => navigate('..');
  const createLink = () => navigate(ROUTES.users.links.createLink.fullPath);

  return (
    <Modal opened title="Создание ссылки" onClose={handleClose}>
      <div className={classes.container}>
        <ul className={classes.list}>
          {USER_LINKS_LIST.map((link) => (
            <li className={classes.item} key={link.id}>
              <Flex direction="column">
                <Text fz="sm">{link.name}</Text>
                <Text fz="xs" c="#667085">
                  {link.author}
                </Text>
              </Flex>
              <Group>
                <Group gap="sm">
                  <LockIcon />
                  <CalendarIcon />
                </Group>
                <MenuButton id={link.id} />
              </Group>
            </li>
          ))}
        </ul>
      </div>
      <Controls>
        <Button fullWidth type="submit" mt="md" onClick={createLink}>
          Создать ссылку
        </Button>
      </Controls>
    </Modal>
  );
};
