import { FC, useCallback, useEffect, useState } from 'react';
import { Text, Button, LoadingOverlay, Box, ScrollArea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { themeColors } from '@/theme/colors';
import { IconPlus } from '@tabler/icons';
import { useStyles } from './styles';
import { useDisclosure } from '@mantine/hooks';
import { AddDomainModal } from './addDomainModal';
import DomainItem from './domainItem';
import { useLazyGetDomainsQuery } from '@/entities/admin-app/email/api';
import { useAppSelector } from '@/hooks/redux/redux';
import { IDomain, IRecordDetails } from '@/entities/admin-app/email';
import { DomainSettings } from './domainSettings';
import { Modal } from '@/components/modal';
import DeleteDomain from './deleteDomain';

interface IDomainSettingsProps {
  isOpened: boolean;
  onClose: () => void;
  serverId: number;
}

const Domains: FC<IDomainSettingsProps> = ({ isOpened, onClose, serverId }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const [domainDetails, setDomainDetails] = useState<IDomain | IRecordDetails>();
  const { filial } = useAppSelector((state) => state.filials);

  const [addDomainStep, setAddDomainStep] = useState(false);
  const [previewStep, setPreviewStep] = useState(false);
  const [deleteStep, setDeleteStep] = useState(false);

  const [getDomainsList, { data: DomainsList, isFetching }] = useLazyGetDomainsQuery();

  const handleChange = useCallback(() => {
    filial?.Id && getDomainsList({ ServerId: serverId, CustomerId: filial?.Id });
  }, [filial]);

  useEffect(() => {
    if (!filial) {
      return;
    }
    if (filial.Id) {
      getDomainsList({ ServerId: serverId, CustomerId: filial?.Id });
    }
  }, [filial]);

  const closePreviewModal = () => {
    setPreviewStep(false);
  };

  const handleCloseAddDomainModal = () => {
    close();
    setAddDomainStep(false);
  };

  const handleBack = () => {
    if (addDomainStep) setAddDomainStep(false);
    else if (deleteStep) setDeleteStep(false);
    else setPreviewStep(false);
  };

  const decideTitle = () => {
    if (addDomainStep) return t('addDomain.title');
    if (previewStep) return `${t('domains.settings')} ${domainDetails?.Domain || ''}`;
    if (deleteStep) return t('domains.delete');
    return t('domains.title');
  };

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      title={decideTitle()}
      zIndex={1300}
      size="600px"
      onBackClick={(addDomainStep || previewStep || deleteStep) && handleBack}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      {!addDomainStep && !previewStep && !deleteStep && (
        <Box p="24px">
          {DomainsList?.length
            ? DomainsList?.map((domain: IDomain, index: number) => {
                return (
                  <DomainItem
                    key={index}
                    domain={domain}
                    setOpenPreviewModal={setPreviewStep}
                    setOpenDeleteModal={setDeleteStep}
                    setDomainDetails={setDomainDetails}
                  />
                );
              })
            : null}
          {isFetching && (
            <Box h={DomainsList ? '' : '100px'}>
              <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} />
            </Box>
          )}
          <Button
            className={classes.addButton}
            size="sm"
            w="100%"
            variant="outline"
            rightSection={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() => {
              open();
              setAddDomainStep(true);
            }}
          >
            <Text>{t('addDomain.action')}</Text>
          </Button>
        </Box>
      )}

      {addDomainStep && opened && (
        <AddDomainModal
          serverId={serverId}
          handleClose={handleCloseAddDomainModal}
          setOpenPreviewModal={setPreviewStep}
          handleChange={handleChange}
          setDomainDetails={setDomainDetails}
        />
      )}

      {previewStep && <DomainSettings handleClose={closePreviewModal} domain={domainDetails} />}

      {deleteStep && (
        <DeleteDomain
          setDeleteStep={setDeleteStep}
          domain={domainDetails}
          handleChange={handleChange}
          setDomainDetails={setDomainDetails}
        />
      )}
    </Modal>
  );
};

export default Domains;
