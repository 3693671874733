import React, { FC } from 'react';
import { Drawer, Group, GroupProps, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ScrollContainer } from '../scroll-container';
import { useStyles } from './styles';

interface ISimpleNavbarProps extends GroupProps {
  widthNav: string | number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const SimpleNavbar: FC<ISimpleNavbarProps> = ({
  isOpen,
  setIsOpen,
  widthNav,
  children,
  ...props
}) => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <>
      {isMobile ? (
        <Drawer
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          zIndex={1002}
          padding="20px 20px 20px 0"
          size={300}
        >
          <Group className={classes.navbar} sx={{ width: 'auto' }} {...props}>
            <ScrollContainer sx={{ height: '100%' }}>
              <Stack className={classes.list} styles={{ root: { flexGrow: 1 } }}>
                {children}
              </Stack>
            </ScrollContainer>
          </Group>
        </Drawer>
      ) : (
        <Group className={classes.navbar} sx={{ width: widthNav }} {...props}>
          <ScrollContainer h="100%" w={widthNav}>
            <Stack className={classes.list} styles={{ root: { flexGrow: 1 } }}>
              {children}
            </Stack>
          </ScrollContainer>
        </Group>
      )}
    </>
  );
};
