import { Layout } from '@/containers/layout';
import { ErrorBoundary } from '@components/error-boundary';
import { UsersSection } from './components/sections/users';
import { RolesSection } from './components/sections/roles';
import { FilialSection } from './components/sections/filial';
import { StatisticsSection } from './components/sections/statistics';
import { Box, Flex } from '@mantine/core';
import { SafetySection } from './components/sections/safety';
import { useAppSelector } from '@/hooks/redux/redux';
import { MailSection } from './components/sections/mail';
import { DiskSpaceSection } from './components/sections/diskSpace';
import { useStyles } from './styles';
import { ModulesSection } from './components/sections/modules';
import { PaymentSection } from './components/sections/payment';
import { isSaaS } from '@/lib/utils/access';
// import { ServicesSection } from './components/sections/services';

export const Home = () => {
  const { classes } = useStyles();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentUserFilial = useAppSelector((state) => state.userInfo?.User?.Customer.Id);

  const flexContainerClass = isSaaS(Settings)
    ? classes.flexContainerSaaS
    : classes.flexContainerDefault;

  const urlParams = new URLSearchParams(window.location.search);
  const paymentsDate = urlParams.get('paymentsDate');

  return (
    <ErrorBoundary componentTitle="Home page">
      <Layout>
        <Box className={classes.homeContainer}>
          <Flex gap="8px" direction="column" className={flexContainerClass}>
            <UsersSection filialId={currentUserFilial} />
            <MailSection filialId={currentUserFilial} />
            <RolesSection />
            {isSaaS(Settings) && <PaymentSection filialId={currentUserFilial} paymentsDate={paymentsDate} />}
            {/* <ServicesSection /> */}
            <StatisticsSection />
            <DiskSpaceSection filialId={currentUserFilial} />
            <FilialSection filialId={currentUserFilial} />
            <ModulesSection filialId={currentUserFilial} />
            <SafetySection filialId={currentUserFilial} />
          </Flex>
        </Box>
      </Layout>
    </ErrorBoundary>
  );
};
