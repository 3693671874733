import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  popover: {
    padding: theme.spacing.xs,
    width: 'max-content'
  },
  itemMenu: {
    padding: theme.spacing.xs,
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '14px',
    gap: 0,

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[7],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[2],
        color: theme.black
      }
    }
  }
}));
