import { FC, useRef, useState } from 'react';
import { Box, Button, Text, UnstyledButton } from '@mantine/core';
import { Modal } from '@components/modal';
import { useStyles } from './styles';
import { AvatarEditor } from '@components/avatar-editor';
import { base64ToBlob } from '@/lib/files';
import { userUploadAvatar } from '@/api/user';
import { useTranslation } from 'react-i18next';
import useUserTask from './useUserTask';
import { universalNotification } from '@/lib/utils/notification';

interface IAvatarEditModalProps {
  isOpened: boolean;
  title: string;
  onClose: (previousAvatar?: string) => void;
  id: number;
}

export const AvatarEditModal: FC<IAvatarEditModalProps> = ({ isOpened, title, onClose, id }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState<string>('');
  const openRef = useRef<() => void>(null);
  const avatarRef = useRef<any>(null);
  const userTask = useUserTask();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUploadError = () => {
    setIsLoading(false);
    setAvatar('');
    universalNotification({
      error: null,
      errorTitle: t('avatar.error'),
      isError: true,
      isSuccess: false
    });
  };

  const saveAvatar = async () => {
    const img = avatarRef?.current?.getImageScaledToCanvas().toDataURL();
    if (img) {
      const file = await base64ToBlob(img);
      const formData = new FormData();
      formData.append('fileName', 'avatar');
      formData.append('formFile', file);
      formData.append('userId', String(id));
      try {
        setIsLoading(true);
        const res = await userUploadAvatar(formData);

        if (res?.status === 200) {
          const taskId = res?.data?.Response?.Data?.Id;
          userTask(taskId, setIsLoading, onClose, setAvatar);
        } else {
          handleUploadError();
        }
      } catch (e) {
        handleUploadError();
        console.log('userUploadAvatar ERROR', e);
      }
    }
  };

  const clickInAvatar = async () => {
    if (openRef.current) {
      openRef.current();
    }
  };

  const close = () => {
    onClose();
    setAvatar('');
  };

  return (
    <Modal size="450px" opened={isOpened} title={title} onClose={close}>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <AvatarEditor
            openRef={openRef}
            avatarRef={avatarRef}
            avatar={avatar}
            setAvatar={setAvatar}
            isLoading={isLoading}
          />
          <Box className={classes.control}>
            <Text size="sm">{t('avatar.conditions')}</Text>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Button size="sm" radius={8} w="49%" onClick={saveAvatar} disabled={!avatar}>
            {t('save')}
          </Button>
          {avatar ? (
            <UnstyledButton ml={8} w="49%" onClick={clickInAvatar} className={classes.cancelBtn}>
              {t('avatar.changePhoto')}
            </UnstyledButton>
          ) : (
            <UnstyledButton ml={8} className={classes.cancelBtn} w="49%" onClick={close}>
              {t('cancel')}
            </UnstyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
