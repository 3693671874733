import { Modal } from '@/components/modal';
import { useVerifyUsersEmail } from '@/containers/pages/filials/hooks/useVerifyUsersEmail';
import { useAppSelector } from '@/hooks/redux/redux';
import { Button, Text, Box, LoadingOverlay, UnstyledButton } from '@mantine/core';
import { Dispatch, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersListByVerifiedFilials } from '@/containers/pages/users/components/modals/send-email/filial-list';
import { useEmailActivation } from '@hooks/users/useEmailActivation';
import { useStyles } from './styles';

interface IActivationModal {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  openSendEmail?: Dispatch<React.SetStateAction<boolean>>;
  selectedUsers: number[];
}
export const SendEmailActivationModal: FC<IActivationModal> = ({
  open,
  setOpen,
  selectedUsers
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { sendEmailActivation, isLoading: isSendingActivation } = useEmailActivation();
  const { users } = useAppSelector((state) => state.users);
  const { isLoading, sortedList, handleClickUser, verifiedIds, isError } = useVerifyUsersEmail({
    selectedUsers,
    users,
    isConfirmModal: true
  });

  const handleConfirm = useCallback(async () => {
    await sendEmailActivation(verifiedIds);
    setOpen(false);
  }, [verifiedIds.length]);

  return (
    <Modal
      size="500px"
      zIndex={1400}
      opened={open}
      onClose={() => setOpen(false)}
      title={t('sendLetter.confirmLetter')}
    >
      <Box className={classes.modalContainer}>
        {!isError ? (
          <Box sx={{ width: '100%' }}>
            <Text mb="25px">{t('sendLetter.sendActivationsDescription')}</Text>
            <UsersListByVerifiedFilials
              isLoading={isLoading}
              sortedList={sortedList}
              handleClickUser={handleClickUser}
            />
          </Box>
        ) : (
          <Text fz="lg">{t('requestsErrors.unknownError')}</Text>
        )}
      </Box>
      <Box className={classes.modalFooter}>
        <Button w="49%" disabled={!verifiedIds.length} onClick={handleConfirm}>
          {t('send')}
        </Button>

        <UnstyledButton w="49%" className={classes.modalCancelBtn} onClick={() => setOpen(false)}>
          {t('cancel')}
        </UnstyledButton>
      </Box>
      <LoadingOverlay visible={isLoading || isSendingActivation} overlayProps={{ blur: 2 }} />
    </Modal>
  );
};
