import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#F9FAFB',
    height: '63px',
    padding: `0px ${theme.spacing.md}`,
    borderTop: '1px solid #F2F4F7',
    position: 'sticky',
    bottom: 0,
    zIndex: 100
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  }
}));
