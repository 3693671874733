import React, { useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  SimpleGrid,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { CalendarExtraIcon, EditIcon } from '@assets/icons';
import { IconCircleX, IconCopy } from '@tabler/icons';
import { useStyles } from '../../../styles';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useDeleteInvitationLinkMutation } from '@/entities/admin-app/auth/api';
import { IInvitationLink } from '@/entities/admin-app/filials';
import { copyToClipboard } from '@/containers/pages/user/utils';
import { formatDate } from '@/lib/utils/date';
import { EditLinkModalContent } from '@/containers/pages/filials/edit/components/tabs/invite-links/components/editLinkModalContent';
import { Modal as CustomModal } from '@components/modal';
import { showNotification } from '@mantine/notifications';
import { universalNotification } from '@/lib/utils/notification';

interface IInviteLinkItemProps {
  invitationLink: IInvitationLink;
  handleChange?: () => void;
  CustomerId: number;
}
export const InviteLinkItem = ({
  invitationLink,
  handleChange,
  CustomerId
}: IInviteLinkItemProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const [
    deleteInvitationLink,
    { isLoading: deleteLinkLoading, isSuccess: deleteLinkSuccess, isError }
  ] = useDeleteInvitationLinkMutation();

  const onClickDelete = async (e: any) => {
    e.preventDefault();
    try {
      const res = await deleteInvitationLink(invitationLink?.Code);
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t(`filials.inviteLinks.linkSuccessDeleted`),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        handleCloseModal();
        if (handleChange) {
          handleChange();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (deleteLinkSuccess && !isError) {
      handleCloseModal();
      if (handleChange) {
        handleChange();
      }
    }
  }, [deleteLinkSuccess, isError]);

  const handleCloseModal = () => {
    setOpenDeleteItemModal(false);
  };

  const isMobile = useMediaQuery('(max-width: 992px)');

  const copyText = async () => {
    try {
      copyToClipboard(invitationLink.Url);
      setLinkCopied(true);
      universalNotification({
        isError: false,
        error: null,
        isSuccess: true,
        successTitle: t('copied')
      });
    } catch (e) {
      setLinkCopied(false);
    }
  };

  return (
    <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
      <Flex w="100%" align="center">
        <Text className={classes.mailText} w="400px">
          {invitationLink?.Name}
        </Text>
      </Flex>
      <Flex w="100%" align="center">
        <TextInput
          className={classes.linkInput}
          rightSection={
            invitationLink.Url && (
              <IconCopy color={linkCopied ? 'teal' : 'blue'} onClick={copyText} />
            )
          }
          value={invitationLink.Url}
          readOnly={true}
        />
        <ActionIcon size={24} mr={20} ml={20} onClick={() => open()}>
          <EditIcon />
        </ActionIcon>

        <Tooltip
          sx={{
            background: '#FFF',
            boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.07)',
            borderRadius: theme.spacing.xs,
            padding: '8px'
          }}
          label={t(
            `filials.inviteLinks.${
              invitationLink.IsActive ? 'validUntil' : 'linkInactiveWasValidUntil'
            }`,
            { date: formatDate(invitationLink?.ExpiredAt) }
          )}
          zIndex="1003"
          c={theme.colors.dark[8]}
        >
          <Box mr={20} mt="auto">
            <CalendarExtraIcon stroke={invitationLink.IsActive ? '#667085' : '#DE0E0E'} />
          </Box>
        </Tooltip>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ActionIcon size={24} onClick={() => setOpenDeleteItemModal(true)}>
            <IconCircleX color="#667085" />
          </ActionIcon>
        </Box>
      </Flex>
      <CustomModal
        opened={opened}
        onClose={close}
        title={t(`filials.inviteLinks.creatingLink`)}
        size={isMobile ? '90%' : '40%'}
      >
        <EditLinkModalContent
          CustomerId={CustomerId}
          invitationLink={invitationLink}
          opened={opened}
          handleClose={close}
          handleChange={handleChange}
          isEditing={true}
        />
      </CustomModal>
      <Modal
        opened={openDeleteItemModal}
        onClose={() => setOpenDeleteItemModal(false)}
        title={
          <Text fw={700} fz="lg">
            {t('warning')}
          </Text>
        }
        zIndex={1002}
      >
        <Box mb="1rem">
          <Text mb="1rem" fz="md">
            {t('filials.inviteLinks.warning')}: <b>{invitationLink?.Name}</b>?
          </Text>
        </Box>

        <SimpleGrid cols={{ base: 1, sm: 2 }} mt="md">
          <UnstyledButton className={classes.modalCancelBtn} onClick={handleCloseModal}>
            {t('cancel')}
          </UnstyledButton>
          <Button color="red" bg="#C23939" onClick={onClickDelete}>
            {t('continue')}
          </Button>
        </SimpleGrid>
        <LoadingOverlay visible={deleteLinkLoading} overlayProps={{ blur: 2 }} />
      </Modal>
    </SimpleGrid>
  );
};
