import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  redirectButton: {
    marginTop: '26px',
    borderRadius: '4px',
    '& .mantine-Button-label': {
      fontSize: '14px',
      fontWeight: 500
    }
  }
}));
