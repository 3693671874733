import React, { FC } from 'react';
import { Flex, Text, Group, Radio, Select, SelectProps } from '@mantine/core';

import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { TableColumnsAction } from '@/components/simple-table/TableColumnsActions';
import { IColumnData } from '@/components/simple-table/types';

interface IHeaderProps {
  handleChangePageSize: (value: number) => void;
  pageSize: number | null;
  pageSizeOptions: { value: string; label: string }[];
  isLoading: boolean;
  startOffset: number;
  endOffset: number;
  totalCount: number;
  showButton?: boolean;
  buttonComponent?: React.ReactElement;
  list?: IColumnData[];
  setColumnsData?: (columns: IColumnData[]) => void;
  setColumnVisibility?: (columns: any) => void;
}

export const TableHeader: FC<IHeaderProps> = ({
  handleChangePageSize,
  pageSize,
  pageSizeOptions,
  isLoading,
  startOffset,
  endOffset,
  totalCount,
  showButton,
  buttonComponent,
  list,
  setColumnsData,
  setColumnVisibility
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const renderSelectOption: SelectProps['renderOption'] = ({ option, checked }) => (
    <Group wrap="nowrap" gap="xs">
      <Radio readOnly checked={checked} size="xs" />
      <Text fz="sm" styles={{ root: { flexShrink: 0 } }}>
        {option.label}
      </Text>
    </Group>
  );

  let displayText;
  if (!isLoading) {
    let offsetsText = `${startOffset}`;
    if (endOffset !== startOffset) {
      offsetsText += `-${endOffset}`;
    }
    const recordsText =
      totalCount % 10 === 1 && totalCount % 100 !== 11
        ? t('statistics.records')
        : t('statistics.records_');
    displayText = `${offsetsText} ${t('statistics.from')} ${totalCount} ${recordsText}`;
  }

  return (
    <Flex justify={showButton ? 'space-between' : 'flex-end'} className={classes.controlWrapper}>
      {showButton && buttonComponent}
      <Flex align="center" className={classes.rightSection} justify="flex-end">
        {isLoading ||
          (totalCount > 0 && (
            <Text className={classes.countText} c={themeColors.brandGray[0]} size="sm" mr="sm">
              {displayText}
            </Text>
          ))}
        <Select
          comboboxProps={{ zIndex: 500 }}
          size="sm"
          value={pageSize?.toString()}
          // eslint-disable-next-line
          // @ts-ignore
          onChange={(value: string) => handleChangePageSize(+value)}
          renderOption={renderSelectOption}
          data={pageSizeOptions}
        />
        {list && (
          <Flex align="center" ml="xs" className={classes.filterSection}>
            <TableColumnsAction
              setColumnsData={setColumnsData}
              list={list}
              setColumnVisibility={setColumnVisibility}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
