import React from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons';
import { useDisclosure } from '@mantine/hooks';
import { useStyles } from './styles';
import { VerifyEmailAndOpenModal } from '@/containers/pages/filials/hooks/useVerifyEmailAndOpenModal';
import { OneUserActivationModal } from '@/containers/pages/users/components/modals/send-activation/oneUserActivation';
import { useTranslation } from 'react-i18next';

interface IEmailActivationControl {
  Id?: number;
  Email?: string;
  customerId: number;
}

export const EmailActivationControl = ({ Id, Email, customerId }: IEmailActivationControl) => {
  const { t } = useTranslation();
  const { theme } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const { handleCheckCustomerServerSettings } = VerifyEmailAndOpenModal();
  const closeModal = () => {
    close();
  };

  if (!Id || !Email) {
    return null;
  }

  const handleOpen = () => {
    open();
  };
  const handleVerifyEmail = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await handleCheckCustomerServerSettings(customerId, handleOpen);
  };

  return (
    <>
      <Tooltip
        sx={{
          background: theme.colors.brandGray[1],
          borderRadius: theme.spacing.xs,
          padding: '8px'
        }}
        color="gray"
        label={t('users.actionList.confirmEmail')}
        zIndex="1101"
        c={theme.colors.dark[8]}
      >
        <ActionIcon variant="transparent" onClick={handleVerifyEmail}>
          <IconAlertTriangle size="20" color="red" stroke={1.5} cursor="pointer" />
        </ActionIcon>
      </Tooltip>
      {opened && (
        <OneUserActivationModal
          open={opened}
          handleClose={closeModal}
          selectedUsers={[Id]}
          email={Email}
        />
      )}
    </>
  );
};
