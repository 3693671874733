import { FC, useContext, useEffect } from 'react';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { Roles } from '@/containers/pages/user/components/sections/roles';
import { isAdmin } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';
import { EditUserInfoContext } from '../../../../index';
import { useForm } from '@mantine/form';
import { Button, LoadingOverlay } from '@mantine/core';
import { IRole } from '@/entities/admin-app/directories';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';

const UserRole: FC = () => {
  const { t } = useTranslation();
  const { userInfoCommon, isLimitedAccess, currentId } = useContext(EditUserInfoContext);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { Roles: userRoles } = userInfoCommon;
  const { saveUpdate, isLoading } = useUpdateInfo();

  const userRolesForm = useForm<{ Roles: IRole[] | null }>({
    initialValues: {
      Roles: []
    },
    validateInputOnChange: true
  });

  const errors = userRolesForm.errors;
  const isDirtyForm = userRolesForm.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  const fromData = {
    Roles:
      userRoles?.map((role) => ({
        Id: role?.Id,
        Name: role?.Name,
        IsRemovable: role?.IsRemovable
      })) || []
  };

  useEffect(() => {
    userRolesForm.setValues(fromData);
    userRolesForm.resetDirty(fromData);
  }, [userRoles]);

  const handleSubmit = async () => {
    const values = {
      Id: currentId,
      [ProfileSectionEnum.Roles]: userRolesForm.values?.Roles ?? null
    };

    await saveUpdate({
      Type: ProfileUpdateTypeEnum.Roles,
      Data: values
    });
    userRolesForm.setValues(fromData);
    userRolesForm.resetDirty(fromData);
  };

  return (
    <form onSubmit={userRolesForm.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.roles')}>
        <Roles
          isEditPage
          Roles={userRolesForm.values?.Roles ?? null}
          fieldName="Roles"
          form={userRolesForm}
          isLimitedAccess={isLimitedAccess}
        />
        {isAdmin(currentUser) && (
          <Button
            type="submit"
            size="sm"
            radius={8}
            disabled={isDisabled}
            mt="md"
            style={{ padding: '0 20px' }}
            data-cy="saveRolesBtn"
          >
            {t('profile.save')}
          </Button>
        )}
        <LoadingOverlay visible={isLoading} />
      </SectionTemplate>
    </form>
  );
};

export default UserRole;
