import { FC } from 'react';
import { Button, Flex, Group, Image, Stack, Text } from '@mantine/core';
import { useStyles } from './styles';

interface NoDataProps {
  title?: string;
  description?: string;
  buttons?: {
    buttonText: string;
    handleClick: () => void;
  }[];
  illustration?: string;
}

export const NoDataContainer: FC<NoDataProps> = ({ title, description, buttons, illustration }) => {
  const { classes } = useStyles();

  return (
    <Stack gap="0" justify="center" flex={1} align="center">
      {illustration && title && (
        <Image
          src={illustration}
          alt={title}
          style={{
            height: 240,
            width: 270,
            objectFit: 'contain'
          }}
        />
      )}
      <Flex align="center" justify="center" direction="column" maw={360}>
        {title && (
          <Text fz="26" ta="center" fw="500" mb="4px">
            {title}
          </Text>
        )}
        {description && (
          <Text fz="16" ta="center" c="#868E96" sx={{ lineHeight: '23px' }}>
            {description}
          </Text>
        )}
        {buttons?.length ? (
          <Group gap="xs" justify="center">
            {buttons.map((button, index) => (
              <Button key={index} onClick={button.handleClick} className={classes.redirectButton}>
                {button.buttonText}
              </Button>
            ))}
          </Group>
        ) : null}
      </Flex>
    </Stack>
  );
};
