import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  photo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  container: {
    marginTop: theme.spacing.sm
  },

  name: {
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '20px'
  },

  email: {
    color: theme.colors.brandGray[0],
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '17px',
    marginTop: theme.spacing.xs
  },

  badge: {
    marginTop: theme.spacing.sm
  }
}));
