import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalFooter: { ...modalTheme(theme).footer, position: 'fixed', bottom: 0 },
  modalCancelBtn: modalTheme(theme).cancelBtn,
  title: {
    fontWeight: 600
  },

  textFieldInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },

  domainText: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    width: '40%',
    padding: '4px',
    paddingLeft: '16px',
    marginRight: '32px'
  },

  domainDisabled: {
    backgroundColor: '#f1f3f5',
    opacity: '0.4'
  },

  addButton: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    maxWidth: '300px',

    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },

  loader: {
    display: 'flex',
    paddingTop: '8px',
    alignItems: 'center'
  },

  grid: {
    alignItems: 'center'
  },

  linkValue: {
    wordBreak: 'break-all'
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing?.sm}`,
    backgroundColor: theme.colors?.brandGray ? theme.colors?.brandGray[1] : '',
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  scrollContainer: {
    maxHeight: '550px',
    overflowY: 'auto',
    paddingRight: theme.spacing?.lg,
    paddingLeft: theme.spacing.sm,
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB',
      borderRadius: '8px'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  }
}));
