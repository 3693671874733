import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  paginateWrapper: {
    overflow: 'auto'
  },
  wrapper: {
    flexGrow: 1,
    overflow: 'auto',
    marginBottom: theme.spacing.md,
    [utils.smallerThan('md')]: {
      marginRight: '0'
    }
  },

  container: {
    position: 'relative',
    backgroundColor: theme.white,
    borderRadius: '8px',
    overflowX: 'hidden',
    [utils.smallerThan('md')]: {
      padding: '10px',
      marginTop: '10px'
    }
  },

  searchContainer: {
    backgroundColor: theme.colors.backgroundGray[0],
    '.mantine-Input-wrapper': {
      width: '100%'
    }
  },

  search: {
    width: '60%',
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '56px',
      fontSize: '16px'
    },
    '& .mantine-Input-section': {
      marginLeft: '15px'
    }
  },

  contentContainer: {
    backgroundColor: theme.white,
    borderRadius: '8px',
    // overflow: 'auto',
    [utils.smallerThan('md')]: {
      padding: '0!important'
    }
  },

  divider: {
    borderColor: theme.colors.backgroundGray[2]
  },

  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: 'white'
  }
}));
