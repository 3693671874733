import React from 'react';
import { Box, Button, Modal, SimpleGrid, Text } from '@mantine/core';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { useStyles } from '@/containers/pages/user/components/sections/linked-emails/styles';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { useTranslation } from 'react-i18next';
import { UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

interface IDeleteEmailModalProps {
  form: UseFormReturnType<any>;
  opened: boolean;
  handleClose: () => void;
  selectedEmail: ILinkedEmail | null;
  sectionFormKey: string;
  editUser?: boolean;
  currentId?: number;
}
export const DeleteEmailModal = ({
  form,
  selectedEmail,
  opened,
  handleClose,
  sectionFormKey,
  editUser,
  currentId
}: IDeleteEmailModalProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { saveUpdate } = useUpdateInfo();
  const modalClose = () => {
    handleClose();
  };

  const handleDeleteEmail = async () => {
    if (editUser && currentId) {
      await saveUpdate({
        Type: ProfileUpdateTypeEnum.Email,
        Data: { [ProfileSectionEnum.LinkedEmail]: [], Id: currentId }
      });

      modalClose();
    } else {
      form.setFieldValue(`${sectionFormKey}`, []);
      showNotification({
        title: t('profile.deletedSuccessMessage'),
        message: '',
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
      modalClose();
    }
  };
  return (
    <Modal
      opened={opened}
      onClose={modalClose}
      title={<Text className={classes.title}>{t('profile.deleteMailAccountTitle')}</Text>}
      zIndex={1300}
    >
      <Box mb="1rem">
        <Text mb="1rem" fz="md">
          {t('profile.areYouSureToDelete')} {selectedEmail?.Email} ?
        </Text>
      </Box>
      <SimpleGrid cols={{ base: 1, xs: 2 }} mt="md">
        <Button color="gray" onClick={modalClose}>
          {t('profile.cancel')}
        </Button>
        <Button color="red" onClick={handleDeleteEmail}>
          {t('profile.untie')}
        </Button>
      </SimpleGrid>
    </Modal>
  );
};
