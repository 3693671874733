import { FC, SyntheticEvent } from 'react';
import { Badge, MenuStylesNames, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';

import { useStyles } from './styles';
import { DropdownMenu } from '@components/dropdown-menu';
import { IActionItem } from '../types';
import { UsersActionTableEnum } from '@/types/enums/users';

interface IBadgeMenuProps {
  item: IActionItem;
  handleClickListItem?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  handleClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
  handleChangeCheckbox?: (event: SyntheticEvent<HTMLInputElement>) => void;
  ddmClassNames: Partial<Record<MenuStylesNames, string>> | undefined;
  ddmWidth?: string;
  disabled?: boolean;
}

export const BadgeMenu: FC<IBadgeMenuProps> = ({
  item,
  handleClickListItem,
  handleClick,
  handleChangeCheckbox,
  ddmClassNames,
  ddmWidth,
  disabled
}) => {
  const { classes } = useStyles();

  const getAttributeForValue = (value: string) => {
    return Object.keys(UsersActionTableEnum).find(
      (key) => UsersActionTableEnum[key as keyof typeof UsersActionTableEnum] === value
    );
  };

  return (
    <>
      {item?.list.length ? (
        <DropdownMenu
          height="auto"
          scrollHeight="auto"
          width={ddmWidth}
          classNames={ddmClassNames}
          list={item.list}
          handleClick={handleClickListItem}
          handleChangeCheckbox={handleChangeCheckbox}
          menuItemType={item.menuItemType}
          position="bottom-start"
        >
          <Badge
            data-type={item.value}
            onClick={handleClick}
            variant="light"
            size="md"
            rightSection={Boolean(item.list.length) && <IconChevronDown size="12px" />}
            className={`${classes.badge} ${disabled ? classes.disabled : ''}`}
            data-cy={getAttributeForValue(item.value)}
          >
            <Text fz="inherit">{item.label}</Text>
          </Badge>
        </DropdownMenu>
      ) : (
        <Badge
          onClick={handleClick}
          data-type={item.value}
          variant="light"
          size="md"
          rightSection={Boolean(item.list.length) && <IconChevronDown size="12px" />}
          className={`${classes.badge} ${disabled ? classes.disabled : ''}`}
          data-cy={getAttributeForValue(item.value)}
        >
          <Text fz="inherit">{item.label}</Text>
        </Badge>
      )}
    </>
  );
};
