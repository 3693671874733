import React, { FC } from 'react';
import { ActionIcon, Menu } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons';

import { useStyles } from './styles';
import { IColumnData } from '@components/simple-table/types';
import { TableColumnsDND } from '@components/TableColumnsDND';

interface IMoreActionProps {
  list: IColumnData[];
  setColumnsData?: (columns: IColumnData[]) => void;
  setColumnVisibility?: (columns: any) => void;
}

export const TableColumnsAction: FC<IMoreActionProps> = ({
  list,
  setColumnsData,
  setColumnVisibility
}) => {
  const { classes } = useStyles();

  return (
    <Menu
      classNames={{ dropdown: classes.dnd }}
      shadow="md"
      zIndex={400}
      position="left-start"
      width={288}
    >
      <Menu.Target>
        <ActionIcon mr={0} data-cy="moreActionsBtn">
          <IconDotsVertical color="#667085" size={18} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown data-cy="dropdownSetColumns">
        <TableColumnsDND
          data={list}
          setColumnsData={setColumnsData}
          setColumnVisibility={setColumnVisibility}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
