import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textTransform: 'none'
  },

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`
  },

  badgeContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  badge: {
    textTransform: 'none',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '50%',
    padding: `0px 6px`
  },

  withoutNumber: {
    fontSize: '10px',
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '50%',
    padding: 0,
    width: '18px',
    height: '18px'
  },

  rangeContainer: {
    width: '100%'
  },

  range: {
    '& .mantine-Slider-bar, .mantine-Slider-thumb': {
      border: `1px solid ${theme.white}`
    }
  },

  control: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: theme.spacing.xs
  },

  controlButton: {
    fontWeight: 500,
    display: 'inline-block',
    width: '100%',
    [utils.dark]: {
      color: theme.colors.dark[0]
    },
    [utils.light]: {
      color: theme.black
    },
    fontSize: theme.fontSizes.sm,
    borderRadius: '8px',

    '&:hover': {
      [utils.dark]: {
        backgroundColor: theme.colors.dark[7],
        color: theme.white
      },
      [utils.light]: {
        backgroundColor: theme.colors.gray[2],
        color: theme.black
      }
    }
  },

  input: {
    borderRadius: '8px',
    border: '1px solid #D0D5DD'
  },

  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.sm}`,
    flexWrap: 'nowrap'
  },

  leftContentText: {
    marginLeft: '0px',
    marginRight: '12px',
    fontWeight: 600
  },

  resetButton: {
    // position: 'absolute',
    // right: '48px'
    // top: '16px'
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  chevron: {
    transition: 'transform 200ms ease'
  }
}));
