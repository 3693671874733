import { useContext } from 'react';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { SectionTemplate } from '../../../../../components/section-template';
import { PanelsEnum } from '@/types/enums/user';
import { SimpleGrid } from '@mantine/core';
import { LinkedListEmails } from '../../../../../components/sections/linked-emails';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { Positions } from '@/containers/pages/user/components/sections/positions';
import { Roles } from '@/containers/pages/user/components/sections/roles';
import { t } from 'i18next';

export const FilialInfo = () => {
  const { form } = useContext(CreateUserContext);
  const { Customer, Positions: userPositions, Roles: userRoles, LinkedEmail } = form.values;
  const CustomerFormKey = 'Customer';

  return (
    <>
      <SectionTemplate title={t('unit')} dataSection={PanelsEnum.company}>
        <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
          <SelectWithPagination
            list={[]}
            label={t('unit')}
            placeholder={t('choose')}
            fieldName={`${CustomerFormKey}.Id`}
            form={form}
            defaultValue={Customer?.Id || null}
            value={Customer?.Id}
            isCreateUser
          />

          {form.getInputProps(`${CustomerFormKey}.Customer.Id`).error}
        </SimpleGrid>
      </SectionTemplate>
      <SectionTemplate title={t('profile.position')} dataSection={PanelsEnum.positions}>
        <Positions Positions={userPositions} form={form} sectionFormKey="Positions" />
      </SectionTemplate>
      <SectionTemplate title={t('profile.roles')} dataSection={PanelsEnum.roles}>
        <Roles Roles={userRoles} fieldName="Roles" form={form} />
      </SectionTemplate>
      <SectionTemplate title={t('profile.mailAccounts')} dataSection={PanelsEnum.mailAccount}>
        <LinkedListEmails
          listLinkedEmails={LinkedEmail}
          form={form}
          sectionFormKey="LinkedEmail"
          filialId={Customer?.Id}
          isCreateUserPage={true}
          editUser={false}
        />
      </SectionTemplate>
    </>
  );
};
