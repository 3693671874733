import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  list: {
    position: 'relative',
    margin: 0,
    padding: 0,
    overflow: 'auto',
    listStyleType: 'none',
    maxHeight: 320
  },
  header: {
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${theme.colors.backgroundGray[6]}`,
    backgroundColor: '#FFFFFF',
    zIndex: 2
  },

  headerElem: {
    width: '100%',
    minWidth: '390px',
    backgroundColor: '#FFFFFF',
    padding: '12px 0',
    gap: '10px'
  },

  progress: {
    marginBottom: theme.spacing.sm,
    marginTop: theme.spacing.sm,
    width: 300
  },
  transparent: {
    opacity: 0.05,
    pointerEvents: 'none'
  },
  box: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80%'
  },

  transferBadge: {
    fontSize: theme.fontSizes.sm,
    lineHeight: '204%',
    border: 'none',
    borderRadius: '34px',
    backgroundColor: '#F2F4F7',
    padding: '4px 12px',
    maxWidth: '160px',
    cursor: 'pointer'
  },

  itemContainer: {
    width: '100%',
    minWidth: '390px',
    alignItems: 'center'
  },

  select: {
    width: '70%',
    minWidth: '200px'
  },

  userInfo: {
    width: '70%',
    minWidth: '110px'
  },

  transferInfo: {
    width: '30%',
    minWidth: '180px'
  },

  overflow: {
    overflow: 'auto'
  }
}));
