import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  root: {
    '& .mantine-Drawer-body': {
      height: '100%',
      padding: theme.spacing.sm
      // overflow: 'auto'
    },

    '& .mantine-Paper-root': {
      width: '384px'
    }
  }
}));
