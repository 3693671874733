import { userTask } from '@/api/user';
import { useLazyCurrentUserQuery } from '@/entities/admin-app/user/api';
import { useLazyGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { UserTask } from '@/types/enums/userTask';
import { useParams } from 'react-router-dom';
import { useActions } from '@/hooks/redux/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { IUser } from '@/entities/admin-app/auth';
import { generateUUID } from '@/utils/uuid';
import { Dispatch, SetStateAction, useContext } from 'react';
import { EditUserInfoContext } from '../../index';
import { usersApi } from '@/entities/admin-app/users/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { universalNotification } from '@/lib/utils/notification';
import { useTranslation } from 'react-i18next';

const useUserTask = () => {
  const { t } = useTranslation();
  const [fetchUser] = useLazyCurrentUserQuery();
  const [fetchUserInfo] = useLazyGetUserInfoQuery();
  const params = useParams();
  const { setUserInfo } = useActions();
  const { currentId, setAvatarKey } = useContext(EditUserInfoContext);
  const { User } = useAppSelector((state) => state.userInfo);

  const handleTaskCompletion = (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    setAvatar: (value: string) => void,
    onClose: (previousAvatar?: string) => void
  ) => {
    const userId = Number(params.id);
    const uuid = generateUUID();
    if (User?.Id === currentId) {
      setUserInfo({
        ...User,
        HasAvatar: true,
        AvatarKey: uuid
      } as IUser);
    } else {
      setAvatarKey(uuid);
    }
    setIsLoading(false);
    setAvatar('');
    onClose();
    universalNotification({
      error: null,
      successTitle: t('profile.avatarEditSuccess'),
      isError: false,
      isSuccess: true
    });
    clearApiCacheByTags(usersApi, ['Users']);
    fetchUser();
    fetchUserInfo(userId);
  };

  const handleTaskFailure = (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    setAvatar: (value: string) => void
  ) => {
    setIsLoading(false);
    setAvatar('');
    universalNotification({
      error: null,
      errorTitle: t('avatar.error'),
      isError: true,
      isSuccess: false
    });
  };

  return async (
    taskId: number,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    onClose: (previousAvatar?: string) => void,
    setAvatar: (value: string) => void
  ) => {
    if (taskId) {
      const result = await userTask(taskId);
      if (UserTask.Done === result.data.Status) {
        handleTaskCompletion(setIsLoading, setAvatar, onClose);
      } else {
        let count = 0;
        const intervalID = setInterval(async () => {
          const repeatRequest = await userTask(taskId);
          count++;
          if (count >= 8) {
            clearInterval(intervalID);
            handleTaskFailure(setIsLoading, setAvatar);
          }

          if (UserTask.Done === repeatRequest.data.Status) {
            clearInterval(intervalID);
            handleTaskCompletion(setIsLoading, setAvatar, onClose);
          }
        }, 800);
      }
    }
  };
};

export default useUserTask;
