import { GenderEnum, usersActionsEnumType } from '@/types/enums';
import { TFunction } from 'i18next';

export const isObjectEmpty = (obj: object | undefined): boolean => {
  if (obj === null || obj === undefined || typeof obj !== 'object') return true;
  return Object.keys(obj).length === 0;
};

export const removeEmptyValues = (obj: { [key: string]: any }): object => {
  for (const key in obj) {
    if (Object.keys(obj).length !== 0) {
      if (
        obj[key] === '' ||
        obj[key] === undefined ||
        obj[key] === null ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const objectToString = (obj: { [key: string]: any }): string => {
  let result = '';
  for (const [key, value] of Object.entries(obj)) {
    result += `${key}: ${value};\n`;
  }
  return result;
};
export const getGenderString = ({ gender, t }: { gender?: GenderEnum; t: TFunction }): string => {
  switch (gender) {
    case GenderEnum.male:
      return t('male');
    case GenderEnum.female:
      return t('female');
    default:
      return t('noInformation');
  }
};

export const numbersToActionType = ({ id, t }: { id?: number; t: TFunction }): string => {
  const reverseMapping: Record<number, string> = {};
  for (const [key, value] of Object.entries(usersActionsEnumType)) {
    reverseMapping[value as number] = key;
  }
  const key = id ? reverseMapping[id] : undefined;

  return key ? t(`statistics.actions.${key}`) : t('noAction');
};

export enum IUserStatus {
  Ban = -2,
  Deleted = -1,
  Active = 1
}

type IUserStatusType = 'Заблокирован' | 'Удаленный' | 'Активный';
export const numberToUserStatus = ({
  value,
  t
}: {
  value: number | null;
  t: TFunction;
}): IUserStatusType => {
  return value === IUserStatus.Ban
    ? t('users.actionList.locked')
    : value === IUserStatus.Deleted
    ? t('users.actionList.deleted')
    : t('users.actionList.active');
};

export const calculatePercentage = (
  total: number | null | undefined,
  chunk: number | null | undefined
): number => {
  if (total && chunk) {
    return parseFloat(((chunk / total) * 100).toFixed(2));
  }
  return 0;
};

const getFirstCharacter = (str: string) => {
  if (!str) {
    return '';
  }
  const codePoint = str.codePointAt(0);
  if (codePoint == null) {
    return '';
  }
  return String.fromCodePoint(codePoint).toUpperCase();
};

export const getUserInitials = (user?: {
  FirstName?: string | null;
  Surname?: string | null;
  SurName?: string | null;
}) => {
  if (!user) {
    return '';
  }

  const firstName = (user.FirstName || '').normalize();
  const surname = (user.Surname || user.SurName || '').normalize();
  const firstNameInitial = firstName ? getFirstCharacter(firstName) : '-';
  const surnameInitial = surname ? getFirstCharacter(surname) : '-';
  return `${surnameInitial}${firstNameInitial}`;
};
