import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing.md
  },

  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing.sm
  },

  controlTextInfo: {
    color: theme.colors.brandGray[0],
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: theme.spacing.md
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#F9FAFB',
    height: '63px',
    padding: `0px ${theme.spacing.md}`,
    borderTop: '1px solid #F2F4F7',
    position: 'sticky',
    bottom: 0,
    zIndex: 1
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  search: {
    marginBottom: theme.spacing.md,
    '& input': {
      border: `1px solid ${theme.colors.brandGray[1]}`
    }
  },

  createGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px 16px 10px 0px',
    width: '250px',
    fontSize: '14px',
    marginBottom: theme.spacing.sm,

    '& svg': {
      marginRight: theme.spacing.xs
    }
  },

  selectAll: {
    // color: theme.colors.mainBlue[9],
    fontSize: '14px',
    marginBottom: theme.spacing.sm,
    cursor: 'pointer'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
