import { Button, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons';
import { Modal } from '@/components/modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';
import { CreateFilialForm } from './createForm';
import { useEffect } from 'react';

export const CreateFilial = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  return (
    <>
      <Button
        size="md"
        data-cy="createFilial"
        leftSection={<IconPlus />}
        className={classes.createButton}
        onClick={open}
      >
        <Text fw="600">{t('create')}</Text>
      </Button>

      <Modal opened={opened} onClose={close} title={t('filials.create')} zIndex={1002}>
        <CreateFilialForm onClose={close} />
      </Modal>
    </>
  );
};
