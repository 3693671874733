import { Box, Text } from '@mantine/core';
import {
  Dispatch,
  FC,
  SetStateAction,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { SelectGroupEnum } from '../..';
import { useGetRolesWithCounterQuery } from '@/entities/admin-app/directories/api';
import { IRole } from '@/entities/admin-app/directories';
import { IListItem } from '@/types';
import { ScrollContainer } from '../../../../scroll-container';
import { Search } from '../../../../search/search-input';
import { GroupItem } from '../../group-item';
import { useStyles } from './styles';

interface IGroupsProps {
  setSelectedGroup: (value: IListItem) => void;
  selectedGroup: IListItem;
  setActiveModal: Dispatch<SetStateAction<string>>;
}

export const Groups: FC<IGroupsProps> = ({ setSelectedGroup, selectedGroup, setActiveModal }) => {
  const { classes } = useStyles();

  const listRef = useRef<IListItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [listData, setListData] = useState<IListItem[]>([]);

  const { data, isSuccess } = useGetRolesWithCounterQuery();

  const handleClickItem = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const value = Number(event.currentTarget.dataset.value);
      const findRole = listData.find((role) => role.value === value);

      if (findRole) {
        setSelectedGroup(findRole);
        setActiveModal(SelectGroupEnum.addNewGroup);
      }
    },
    [listData]
  );

  const resetSelectIdGroup = () => {
    setSelectedGroup({} as IListItem);
  };

  useEffect(() => {
    if (data?.length && isSuccess) {
      const convertData = data.map((item: IRole) => ({
        label: item.Name,
        value: item.Id,
        checked: false,
        counter: item.Counter
      }));
      listRef.current = convertData;
      setListData(convertData);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      const filterSearch = listRef.current.filter((item) =>
        item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      setListData(filterSearch);
    } else {
      setListData(listRef.current);
    }
  }, [search]);

  return (
    <Box className={classes.root}>
      <Box className={classes.group}>
        <Text>Групп найдено</Text>
        <Text>{listData.length ?? 0}</Text>
      </Box>

      <Box className={classes.control}>
        <Text>Группы</Text>
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder="Поиск по группам"
        />

        <ScrollContainer sx={{ height: '300px', width: '100%' }}>
          <Text className={classes.selectAll} onClick={resetSelectIdGroup}>
            Сбросить
          </Text>
          {listData?.map((item, index) => {
            return (
              <GroupItem
                key={index}
                checked={item.checked}
                label={item.label}
                handleClick={handleClickItem}
                value={Number(item.value)}
                counter={item.counter}
                selectedIdGroup={Number(selectedGroup.value) ?? null}
              />
            );
          })}
        </ScrollContainer>
      </Box>
    </Box>
  );
};
