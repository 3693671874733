import React, { FC } from 'react';
import { Box } from '@mantine/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { IActionDocsTitles } from '@/entities/admin-app/statistics';
import useReplaceTitlesWithLinks from '../../../hooks/useReplaceTitlesWithLinks';
interface IDescriptionProps {
  DescriptionTitle?: string;
  Titles?: IActionDocsTitles[];
}

export const Description: FC<IDescriptionProps> = ({ DescriptionTitle, Titles }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const descriptionParts = useReplaceTitlesWithLinks({ DescriptionTitle, Titles });

  return (
    <Box className={classes.listGroup}>
      {DescriptionTitle?.length ? descriptionParts : t('noData')}
    </Box>
  );
};
