import { IFilialQuota } from '@/entities/admin-app/quotas';
import { formatDataSizeUnits } from '@/lib/utils/format';
import { Box, Text, Progress } from '@mantine/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProgressBarProps {
  quotaDetails?: IFilialQuota;
}

export const ProgressBar: FC<IProgressBarProps> = ({
  quotaDetails: { QuotaSize, UsedSize } = {}
}) => {
  const { t } = useTranslation();

  const progressValue = QuotaSize && UsedSize && QuotaSize > 0 ? (UsedSize / QuotaSize) * 100 : 0;
  const displayValue = QuotaSize === 0 ? (UsedSize !== 0 ? 100 : 0) : progressValue;

  return (
    <Box mb="16px">
      <Progress size="xl" value={displayValue} color="#4DABF7" radius="2px" />
      <Text fz="12px" c="#848E98" mt="4px">
        {t('filials.diskSpace.table.used')} {formatDataSizeUnits(UsedSize)}
        {QuotaSize === 0 ? '' : ` ${t('statistics.from')} ${formatDataSizeUnits(QuotaSize)}`}
      </Text>
    </Box>
  );
};
