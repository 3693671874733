import { Button, Group, Stack, Text } from '@mantine/core';
import { useStyles } from './styles';
import { FC } from 'react';

interface IPaperContainer {
  icon: string;
  description: string;
  buttonText: string;
  redirect?: string;
}

export const MessageContainer: FC<IPaperContainer> = ({
  icon,
  description,
  buttonText,
  redirect
}) => {
  const { classes } = useStyles();

  return (
    <Stack align="center" justify="center" className={classes.errorContent}>
      <Group justify="center" gap="xs">
        <img src={icon} alt="icon" className={classes.icon} />
      </Group>
      <Text className={classes.text}>{description}</Text>
      <Group justify="center" w="100%">
        <Button radius="md" w="100%" size="lg" component="a" href={redirect}>
          {buttonText}
        </Button>
      </Group>
    </Stack>
  );
};
