import { Modal } from '@/components/modal';
import { Controls } from '@/components/modal/components/controls';
import { Button, Text, Box, Group, LoadingOverlay } from '@mantine/core';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useEmailActivation } from '@hooks/users/useEmailActivation';

interface IActivationModal {
  open: boolean;
  handleClose: () => void;
  selectedUsers: number[];
  email?: string;
}
export const OneUserActivationModal: FC<IActivationModal> = ({
  open,
  selectedUsers,
  email,
  handleClose
}) => {
  const { t } = useTranslation();
  const { sendEmailActivation, isLoading: isSendingActivation } = useEmailActivation();

  const handleConfirm = useCallback(async () => {
    await sendEmailActivation(selectedUsers);
    if (handleClose) {
      handleClose();
    }
  }, [selectedUsers.length]);

  return (
    <Modal
      size="500px"
      zIndex={1400}
      opened={open}
      onClose={() => handleClose()}
      title={t('users.actionList.emailConfirmation')}
    >
      <Box p="24px">
        <Text>
          <Trans i18nKey="sendLetter.sendActivationToEmail" values={{ email: email }} />
        </Text>
      </Box>
      <Controls>
        <Group grow>
          <Button onClick={handleConfirm}>{t('send')}</Button>
        </Group>
      </Controls>
      <LoadingOverlay visible={isSendingActivation} overlayProps={{ blur: 2 }} />
    </Modal>
  );
};
