import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  boxContainer: {
    border: '4px solid #FF6B6B',
    borderRadius: '8px',
    padding: '8px 16px 16px 16px'
  },

  spaceBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF5F5',
    borderRadius: '13px',
    padding: '3px 8px 3px 3px',
    maxWidth: 'fit-content'
  },

  infoBox: {
    padding: '6px',
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: '#FFF5F5',
    borderRadius: '13px'
  },

  infoGroup: {
    color: '#848E98'
  }
}));
