import { showNotification } from '@mantine/notifications';
import { LinkInviteForm } from '@/containers/pages/filials/edit/components/tabs/invite-links/components/linkInviteForm';
import { LoadingOverlay } from '@mantine/core';
import { IInvitationLink, ILinkInviteFormData } from '@/entities/admin-app/filials';
import { useLazyUpdateInvitationLinkQuery } from '@/entities/admin-app/auth/api';
import { useTranslation } from 'react-i18next';
import { setToEndOFDate } from '@/containers/pages/filials/utils';

interface IEditInviteLinkModalProps {
  handleChange?: () => void;
  invitationLink?: IInvitationLink;
  opened: boolean;
  handleClose: () => void;
  isEditing: boolean;
  CustomerId: number;
}
export const EditLinkModalContent = ({
  handleChange,
  invitationLink,
  opened,
  handleClose,
  isEditing,
  CustomerId
}: IEditInviteLinkModalProps) => {
  const { t } = useTranslation();
  const [updateInvitationLink, { isError, isFetching }] = useLazyUpdateInvitationLinkQuery();
  const handleUpdateLink = async (values: ILinkInviteFormData) => {
    try {
      const res = await updateInvitationLink({
        Name: values?.name,
        ExpiredAt: setToEndOFDate(values?.date),
        Code: invitationLink?.Code || '',
        CustomerId
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('saved'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        handleClose();
        if (handleChange) {
          handleChange();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <LinkInviteForm
        invitationLink={invitationLink}
        isEditing={isEditing}
        handleChangeLink={handleUpdateLink}
        handleClose={handleClose}
        opened={opened}
        isFetching={isFetching}
      />
      <LoadingOverlay visible={isFetching && !isError} overlayProps={{ blur: 2 }} />
    </>
  );
};
