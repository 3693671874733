import {
  Dispatch,
  FC,
  SetStateAction,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Box, Button, Loader, Text } from '@mantine/core';
import { IRole } from '@/entities/admin-app/directories';
import { IListItem } from '@/types';
import { Search } from '../../../search/search-input';
import { useStyles } from './styles';
import { IQueryTable, IQueryUsersTable } from '@/types/api';
import { IUsersInitialFilters } from '@/types/enums/users';
import { useGetRolesQuery } from '@/entities/admin-app/roles/api';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { IFiltersEnumType } from '@/types/enums';
import { convertToFilterConditions } from '@/containers/pages/users/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { GroupItem } from '../../select-group-simple/group-item';

interface IRolesProps {
  filters: IQueryTable<IUsersInitialFilters>;
  setFilters: Dispatch<SetStateAction<IQueryTable<IUsersInitialFilters>>>;
  filial?: IFilialsInfoCommon | null;
  totalCount: number;
  SkipIds?: number[];
}

export const Roles: FC<IRolesProps> = ({ filters, setFilters, filial, totalCount, SkipIds }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const scrollRef = useRef<any>(null);
  const listRef = useRef<IListItem[]>([]);
  const [search, setSearch] = useState<string>('');

  const [listData, setListData] = useState<IListItem[]>([]);
  const [selectedIdGroup, setSelectedIdGroup] = useState<number | null>(null);
  const [roleFilters, setRoleFilters] = useState<IQueryUsersTable>({
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: search,
    FilterConditions: filial
      ? [
          {
            Type: IFiltersEnumType.FilialFilter,
            NumValues: [filial?.Id]
          }
        ]
      : [],
    SkipIds: SkipIds ? SkipIds : []
  });

  const { data, isSuccess, isLoading } = useGetRolesQuery({
    Count: 20,
    Offset: roleFilters.Offset,
    SortOrder: roleFilters.SortOrder,
    SortField: roleFilters.SortField,
    Filters: roleFilters.Filters,
    Query: search,
    FilterConditions: roleFilters.FilterConditions,
    SkipIds: roleFilters.SkipIds
  });

  const handleClickItem = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const value = Number(event.currentTarget.dataset.value);

    setSelectedIdGroup(value);
  }, []);

  const resetRoles = () => {
    setSelectedIdGroup(null);
  };

  useEffect(() => {
    if (data?.Items?.length && isSuccess) {
      const convertData = data?.Items?.map((item: IRole) => ({
        label: item.Name,
        value: item.Id,
        checked: false,
        counter: item.Counter
      }));
      const convertTotalData = roleFilters.Offset > 0 ? [...listData, ...convertData] : convertData;
      listRef.current = convertTotalData;
      setListData(convertTotalData);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      const filterSearch = listRef.current.filter((item) =>
        item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      setListData(filterSearch);
      return;
    }
    setListData(listRef.current);

    if (!filters.Filters?.roles?.length) {
      return;
    }
  }, [search]);

  useEffect(() => {
    const roleIds = selectedIdGroup ? [selectedIdGroup] : [];
    const roleType = IFiltersEnumType.RoleFilter.toString();

    setFilters((prevState) => ({
      ...prevState,
      Offset: 0,
      FilterConditions: selectedIdGroup ? convertToFilterConditions({ [roleType]: roleIds }) : []
    }));
  }, [selectedIdGroup]);

  const handleLoadMore = () => {
    const newOffset = roleFilters.Offset + 20;
    if (data?.TotalCount && newOffset < data?.TotalCount) {
      setRoleFilters({
        ...roleFilters,
        Offset: newOffset
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Button
        onClick={() => resetRoles()}
        fullWidth
        classNames={{
          root: classes.usersButton,
          label: classes.usersButtonLabel
        }}
        variant="default"
        radius="md"
        bg="#EAECF0"
        h={44}
        mb={22}
      >
        <Text span size="sm" fw="600">
          {t('users.allUsers')}
        </Text>
        <Text span size="sm" fw="600">
          {totalCount}
        </Text>
      </Button>

      <Box className={classes.control}>
        <Text fw="600" fz="14">
          {t('profile.roles')}
        </Text>
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('selectGroup.searchRole')}
        />
        {/* <Text size="sm" className={classes.selectAll} onClick={resetSelectIdGroup}>
          Сбросить
        </Text> */}
        <Box sx={{ height: '300px', width: '100%' }}>
          {isLoading && (
            <Box className={classes.loader}>
              <Loader size="sm" />
            </Box>
          )}
          {listData?.length ? (
            <InfiniteScroll
              ref={scrollRef}
              dataLength={listData?.length || 20}
              next={handleLoadMore}
              hasMore={data ? data?.TotalCount > listData?.length : false}
              loader={
                listData?.length ? (
                  <Box className={classes.infiniteScrollMoreData}>
                    <Loader size="sm" />
                  </Box>
                ) : null
              }
              className={classes.infiniteScroll}
              height="300px"
            >
              {listData?.map((item, index) => {
                return (
                  <GroupItem
                    key={index}
                    checked={item.checked}
                    label={item.label}
                    handleClick={handleClickItem}
                    value={Number(item.value)}
                    // counter={item.counter}
                    selectedIdGroup={selectedIdGroup}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Text className={classes.emptyListText}>{t('requestsErrors.roleNotFound')}</Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};
