import { createStyles } from '@mantine/emotion';

interface ILogoField {
  dimensions: { width: number; height: number };
}
export const useStyles = createStyles((theme, { dimensions }: ILogoField, utils) => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #D0D5DD',
    borderRadius: theme.radius.md,
    position: 'relative',
    overflow: 'hidden',
    height: `${dimensions.height + 16}px`,
    width: `${dimensions.width + 16}px`,
    maxWidth: `${dimensions.width + 16}px`,
    maxHeight: `${dimensions.height + 16}px`,
    padding: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    [utils.smallerThan('xs')]: {
      maxWidth: '300px'
    }
  },

  imageOverlay: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '.3s ease',
    opacity: 0,
    backgroundColor: theme.colors.dark[9],
    textAlign: 'center',
    '&:hover': {
      opacity: '0.7'
    }
  },
  description: {
    fontSize: '12px',
    lineHeight: '17.5px',
    color: theme.colors.brandGray[0],
    fontWeight: 500,
    marginBottom: theme.spacing.xs
  }
}));
