import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  rounded: {
    fontSize: '14px',
    lineHeight: '125%',
    fontWeight: 'normal',
    border: 'none',
    borderRadius: '34px',
    backgroundColor: theme.colors.backgroundGray[2],
    padding: '8px 12px',
    margin: '4px'
  },
  listGroup: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'transparent'
  }
}));
