import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((_, __, utils) => ({
  container: {
    position: 'relative',
    width: '90px',
    height: '90px',
    [utils.largerThan('xs')]: {
      width: '124px',
      height: '124px'
    }
  },

  avatar: {
    cursor: 'pointer',
    width: '90px',
    height: '90px',
    [utils.largerThan('xs')]: {
      width: '124px',
      height: '124px'
    },

    img: {
      width: '90px',
      height: '90px',
      borderRadius: '50%',
      [utils.largerThan('xs')]: {
        width: '124px',
        height: '124px'
      }
    },

    svg: {
      display: 'none'
    }
  },

  overlay: {
    backgroundColor: '#000000',
    opacity: 0.6,
    borderRadius: 62,
    cursor: 'pointer',
    zIndex: 1,
    width: '90px'
  },

  text: {
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
    color: '#ffffff',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));
