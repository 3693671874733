import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  root: {
    width: '100%'
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white'
  },

  controlActive: {
    [utils.dark]: {
      backgroundColor: theme.colors.dark[7],
      color: theme.white
    },
    [utils.light]: {
      backgroundColor: theme.colors.gray[2],
      color: theme.black
    }
  },

  search: {
    marginBottom: theme.spacing.xs,
    width: '100%',
    '& input': {
      height: '36px',
      fontSize: '14px',
      border: `1px solid ${theme.colors.brandGray[1]}`
    }
  },

  selectAll: {
    fontSize: '12px',
    fontWeight: 600,
    paddingLeft: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
    cursor: 'pointer'
  },

  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  infiniteScroll: {
    // minHeight: '100px',
    // height: 'auto',
    // maxHeight: '200px',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB',
      borderRadius: '8px'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  noDataText: {
    color: '#228be6'
  }
}));
