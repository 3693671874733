import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${theme.spacing.xs} 0px `,
    width: '100%'
  },

  title: {
    color: theme.colors.brandGray[0],
    fontSize: '12px'
  },

  description: {
    fontSize: '14px',
    lineHeight: '204%'
  },

  link: {
    cursor: 'pointer'
  },

  rounded: {
    border: 'none',
    borderRadius: '34px',
    backgroundColor: '#F2F4F7',
    padding: '4px 12px',
    margin: '8px 4px'
  },

  group: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },

  listGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));
