import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  content: {
    marginTop: theme.spacing.sm,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },

  header: {
    fontSize: '14px',
    marginBottom: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  description: {
    fontSize: '14px',
    color: '#C01048',
    marginRight: theme.spacing.xs,
    cursor: 'pointer'
  },

  emptyData: {
    fontSize: '14px',
    color: theme.colors.brandGray[0]
  }
}));
