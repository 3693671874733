import {
  TextInput,
  Button,
  Textarea,
  Group,
  Box,
  UnstyledButton,
  LoadingOverlay
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Dispatch, FC } from 'react';
import { z } from 'zod';
import { useLazySendEmailServerQuery } from '@/entities/admin-app/email/api';
import { ISendEmailItem } from '@/entities/admin-app/email';
import { getValidateErrors } from '@/lib/utils/validation';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';

interface ISendMailModalProps {
  setOpenModal: Dispatch<React.SetStateAction<boolean>>;
  selection: number[];
}

export const SendMailModal: FC<ISendMailModalProps> = ({ setOpenModal, selection }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [fetchSendEmail, { isLoading }] = useLazySendEmailServerQuery();

  const dataForm: ISendEmailItem = {
    Subject: '',
    Body: '',
    UserIds: []
  };

  const validateScheme = z.object({
    Subject: z.string().min(1, { message: t('sendLetter.errors.noSubject') }),
    Body: z.string().min(1, { message: t('sendLetter.errors.noText') })
  });

  const form = useForm({
    initialValues: dataForm,
    validateInputOnChange: true,
    validate: (values: ISendEmailItem): FormErrors => getValidateErrors(validateScheme, values)
  });

  const isDisabled = Object.keys(form.errors).length !== 0 || !form.isDirty();

  const onSubmit = async (values: ISendEmailItem) => {
    const data = { ...values, UserIds: selection };
    try {
      const res = await fetchSendEmail(data);
      if (res.error) {
        if ('data' in res.error) {
          const data = res.error?.data as { ErrorCode: number };
          const errorMessage = generateErrorMessage(data.ErrorCode);

          showNotification({
            title: t('sendLetter.errors.notSent'),
            message: errorMessage,
            autoClose: true,
            color: 'red'
          });
        }
      } else {
        setOpenModal(false);
        showNotification({
          title: t(`sendLetter.${selection?.length === 1 ? 'successOne' : 'successMultiple'}`),
          message: '',
          autoClose: true,
          color: 'green'
        });
        form.reset();
      }
    } catch (err: any) {
      console.warn(t('sendLetter.errors.notSent'), err);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    form.reset();
  };

  return (
    <>
      <form className={classes.root} onSubmit={form.onSubmit(onSubmit)}>
        <Box py="16px" px="24px">
          <Group justify="center">
            <TextInput
              className={classes.title}
              label={t('sendLetter.subject')}
              placeholder={t('sendLetter.subjectPlaceholder')}
              data-autofocus
              {...form.getInputProps('Subject')}
            />
            <Textarea
              className={classes.description}
              autosize
              minRows={2}
              {...form.getInputProps('Body')}
            />
          </Group>
        </Box>
        <Box className={classes.modalFooter}>
          <Button w="49%" type="submit" disabled={isDisabled}>
            {t('send')}
          </Button>
          <UnstyledButton w="49%" className={classes.modalCancelBtn} onClick={() => handleClose()}>
            {t('cancel')}
          </UnstyledButton>
        </Box>
      </form>
      <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} />
    </>
  );
};
