import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  container: {
    alignItems: 'flex-end',
    marginTop: `${theme.spacing.md}`,
    width: '100%'
  },

  linkInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      fontWeight: 400
    },

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },
    '& .mantine-TextInput-rightSection': {
      color: '#175CD3',
      cursor: 'pointer'
    },
    '& .mantine-Text-root': {
      padding: '0'
    }
  },

  resetPasswordButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',
    '&:hover': {
      backgroundColor: '#eff0f4',
      color: '#101828'
    },
    '&:disabled': {
      color: theme.colors.brandGray[7],
      backgroundColor: theme.colors.brandGray[2]
    }
  },

  modalControls: {
    background: theme.colors.white,
    width: '100%'
  }
}));
