import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { useDeleteQuotaMutation } from '@/entities/admin-app/quotas/api';
import { IQuota } from '@/entities/admin-app/quotas';
import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { showNotification } from '@mantine/notifications';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { useActions } from '@hooks/redux/action';

export const useDeleteQuotaModal = (quota: IQuota) => {
  const [deleteQuota] = useDeleteQuotaMutation();
  const { setQuotasFilters } = useActions();
  const { t } = useTranslation();

  const handleDeleteQuota = async (id: number) => {
    try {
      const res = await deleteQuota(id);
      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('deleted'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        setQuotasFilters({
          Count: 10,
          Offset: 0
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteQuotaModal = () => {
    const { id, name, users } = quota;
    if (!users) {
      handleDeleteQuota(id);
      return;
    }

    const usersText =
      users % 10 === 1 && users % 100 !== 11 ? 'пользователь останется' : 'пользователей останутся';
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: (
          <Box mb={24}>
            {t('filials.diskSpace.deleteUsersQuotaDescription', {
              name: name,
              users: users,
              usersText: usersText
            })}
          </Box>
        ),
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%',
            '& .mantine-Button-label': {
              color: 'black'
            }
          }
        },
        labels: { confirm: t('delete'), cancel: t('cancel') },
        onConfirm: () => handleDeleteQuota(quota.id)
      }
    });
  };

  return { openDeleteQuotaModal /*, data, isSuccess */ };
};
