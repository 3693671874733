import React from 'react';
import { Box, Text, Button, Stack, TextInput } from '@mantine/core';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';

interface InfoStepProps {
  form: any;
  classes: any;
  t: TFunction;
  isDisableCompany?: boolean;
}

export const InfoStep = ({ form, t, classes, isDisableCompany = false }: InfoStepProps) => {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <Text className={classes.subtitle}>{t('register.hasAccount')}</Text>
        <Button className={classes.link} component={Link} to="/login" variant="subtle">
          {t('register.enter')}
        </Button>
      </Box>
      <Stack gap="xs">
        <Box sx={{ gap: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <TextInput
            size="lg"
            radius="md"
            variant="filled"
            name="FirstName"
            placeholder={t('register.firstName')}
            {...form.getInputProps('FirstName')}
          />
          <TextInput
            size="lg"
            radius="md"
            variant="filled"
            name="Surname"
            placeholder={t('register.surname')}
            {...form.getInputProps('Surname')}
          />
        </Box>
        <TextInput
          size="lg"
          radius="md"
          variant="filled"
          name="Company"
          disabled={isDisableCompany}
          placeholder={t('register.company')}
          {...form.getInputProps('Company')}
        />
      </Stack>
    </Box>
  );
};
